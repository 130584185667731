import {
  call,
  all,
  put,
  takeLeading,
  takeEvery,
  delay,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { ActionPalyoad } from 'src/helpers/redux';
import { IResponse } from 'src/services/RomanticAI/http';
import * as dialogService from 'src/services/RomanticAI/dialog';
import * as botService from 'src/services/RomanticAI/bot';
import { analyticsEvents } from 'src/services/analytics/events';
import { actions as accountActions } from 'src/redux/modules/account/actions';
import { selectors as accountSelectors } from 'src/redux/modules/account/selectors';
import { IPricingRules } from 'src/services/RomanticAI/user';
import * as moduleState from './state';
import { actions } from './actions';
import { selectors } from './selectors';
import { setters } from './index';

import * as utils from './utils';
import { getSession, setSession } from 'src/services/RomanticAI/session';

export default function* sagas() {
  yield all([
    takeLeading(
      actions.getDialogs.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getDialogs.pending>) {
        try {
          const dialogs: IResponse<dialogService.IGetDialogsData> = yield call(
            dialogService.getDialogs
          );

          yield put(setters.setDialogs(dialogs.data));

          yield put(actions.getDialogs.fulfilled());
        } catch (error) {
          yield put(actions.getDialogs.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getDialogHistory.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getDialogHistory.pending>
      ) {
        try {
          const { dialogId, from_message_id, count } = action.payload;
          yield put(setters.setBotMessageTyping(undefined));

          const dialogHistory: IResponse<dialogService.IGetDialogHistoryData> =
            yield call(dialogService.getDialogHistory, {
              dialogId,
              from_message_id,
              reverse: true,
              count,
            });

          yield put(
            setters.setDialogHistory({
              ...dialogHistory.data,
              messages: dialogHistory.data.messages,
              id: dialogId,
              hasMore: dialogHistory.data.messages.length >= count,
            })
          );

          yield put(actions.getDialogHistory.fulfilled());
        } catch (error) {
          yield put(actions.getDialogHistory.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getDialogHistoryMore.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getDialogHistoryMore.pending>
      ) {
        try {
          const { dialogId, from_message_id, count } = action.payload;
          yield put(setters.setBotMessageTyping(undefined));

          const dialogHistory: IResponse<dialogService.IGetDialogHistoryData> =
            yield call(dialogService.getDialogHistory, {
              dialogId,
              from_message_id,
              reverse: true,
              count,
            });

          yield put(
            setters.setDialogHistoryAppend({
              ...dialogHistory.data,
              messages: dialogHistory.data.messages,
              id: dialogId,
              hasMore: dialogHistory.data.messages.length >= count,
            })
          );
          yield delay(5000);

          yield put(actions.getDialogHistoryMore.fulfilled());
        } catch (error) {
          yield put(actions.getDialogHistoryMore.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.clearGetDialogHistory.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getDialogHistory.pending>
      ) {
        try {
          yield put(setters.setClearGetDialogHistory());
          yield put(actions.clearGetDialogHistory.fulfilled());
        } catch (error) {
          yield put(actions.clearGetDialogHistory.rejected(error as Error));
        }
      }
    ),
    takeEvery(
      actions.postDialogMessage.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postDialogMessage.pending>
      ) {
        const { dialogId, messages, has_subscribe } = action.payload;
        const optimisticMessages = messages.map(({ data }) =>
          utils.createMessage(data)
        );

        try {
          // const dialog: moduleState.IStateData['dialogHistory'] = yield select(selectors.dialogHistory);
          const pricingRules: IPricingRules = yield select(
            accountSelectors.pricingRules
          );

          if (pricingRules.flow === 'old') {
            // if (
            //   !has_subscribe &&
            //   dialog.bot_monetization_parameters?.count_messages_before_paywall !== undefined &&
            //   dialog.bot_monetization_parameters?.count_messages_before_paywall !== 1
            // ) {
            //   if (dialog.bot_monetization_parameters?.count_messages_before_paywall === 0) {
            //     yield put(actions.highDemandAlertOpen.pending(true));
            //     yield put(actions.postDialogMessage.fulfilled());
            //     return;
            //   }

            // } else {
            const highDemand = has_subscribe
              ? false
              : utils.getHighDemand(
                  true,
                  pricingRules.chat?.total_messages_before_pw
                );
            yield put(setters.setHighDemand(highDemand));

            if (highDemand) {
              yield put(actions.highDemandAlertOpen.pending(true));
              yield put(actions.postDialogMessage.fulfilled());
              return;
            }
            // }
          }

          yield put(
            setters.setUserMessage({
              dialogId,
              messages: optimisticMessages,
            })
          );

          const dialogMessage: IResponse<dialogService.IPostDialogMessageData> =
            yield call(dialogService.postDialogMessage, {
              has_subscribe,
              dialogId,
              messages,
            });

          yield put(
            accountActions.changeWalletBalance.pending({
              balance: dialogMessage.data.web_balance,
            })
          );

          const bot: moduleState.IStateData['bot'] = yield select(
            selectors.bot
          );

          try {
            const [message] = messages;
            if (message.data === dialogService.SystemMessages.continue) {
              analyticsEvents.userSendContinue({
                bot_name: bot?.name || '',
              });
            } else if (
              message.data === dialogService.SystemMessages.sendMePicture
            ) {
              analyticsEvents.sendMeAPictureButtonClick({
                bot_name: bot?.name || '',
              });
            } else {
              analyticsEvents.userSentMessage({
                bot_id: bot?.id?.toString(),
                bot_name: bot?.name || '',
                bot_category: bot?.priority_category,
              });
            }
          } catch {}

          yield put(actions.postDialogMessage.fulfilled());
          yield put(setters.setBotMessageTyping(undefined));

          yield delay(2000);

          yield put(
            setters.setUserMessageUpdate({
              dialogId,
              messages: optimisticMessages.map((message) => ({
                ...message,
                isError: false,
                isLoading: false,
              })),
            })
          );
        } catch (error) {
          yield put(
            setters.setUserMessageUpdate({
              dialogId,
              messages: optimisticMessages.map((message) => ({
                ...message,
                isError: true,
                isLoading: false,
              })),
            })
          );
          yield put(actions.postDialogMessage.rejected(error as Error));
        }
      }
    ),

    takeEvery(
      actions.appendChatUserMessage.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.appendChatUserMessage.pending>
      ) {
        try {
          const { dialogId, messages } = action.payload;

          yield put(setters.setAppendChatUserMessage({ dialogId, messages }));
          yield put(actions.appendChatUserMessage.fulfilled());
        } catch (error) {
          yield put(actions.appendChatUserMessage.rejected(error as Error));
        }
      }
    ),
    takeEvery(
      actions.addDialogChatBotMessage.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.addDialogChatBotMessage.pending>
      ) {
        try {
          const { dialogId, message } = action.payload;

          yield delay(2000);
          {
            const isTyping: boolean | undefined = yield select(
              selectors.isTyping
            );

            if (!isTyping) {
              yield put(setters.setBotMessageTyping(true));
            }
          }

          yield delay(5000);
          {
            const isTyping: boolean | undefined = yield select(
              selectors.isTyping
            );
            if (isTyping === true) {
              yield put(setters.setBotMessageTyping(false));
            }

            yield put(setters.setBotMessage({ dialogId, messages: [message] }));
          }
          const bot: moduleState.IStateData['bot'] = yield select(
            selectors.bot
          );
          if (message.type === 'text') {
            analyticsEvents.botSentMessage({
              bot_name: bot?.name || '',
              bot_category: bot?.priority_category,
              sexting: Boolean(message?.is_sexting_reply),
            });
          }
          if (message.type === 'img') {
            analyticsEvents.botSentImage({
              bot_name: bot?.name || '',
              bot_category: bot?.priority_category,
              reason: message.reason || '',
              picture_generation_type: message?.context_generation_type,
            });
          }

          yield put(actions.addDialogChatBotMessage.fulfilled());
        } catch (error) {
          yield put(actions.addDialogChatBotMessage.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.highDemandAlertOpen.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.highDemandAlertOpen.pending>
      ) {
        try {
          yield put(setters.setHighDemandAlertOpen(action.payload));

          yield put(actions.highDemandAlertOpen.fulfilled());
        } catch (error) {
          yield put(actions.highDemandAlertOpen.rejected(error as Error));
        }
      }
    ),
    takeEvery(
      actions.postDialogMessageUnblur.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postDialogMessageUnblur.pending>
      ) {
        try {
          const { dialogId, messageId, price, bot_name, priority_category } =
            action.payload;

          const message: IResponse<dialogService.IPostDialogMessageUnblurData> =
            yield call(dialogService.postDialogMessageUnblur, {
              dialogId,
              messageId,
            });

          yield put(
            accountActions.changeWalletBalance({
              balance: message.data.balance,
            })
          );
          analyticsEvents.purchasedUnblurPhoto({
            price,
            bot_name,
            bot_category: priority_category,
          });
          yield put(setters.setMessageUnblur(message.data));

          yield put(actions.postDialogMessageUnblur.fulfilled());
        } catch (error) {
          yield put(actions.postDialogMessageUnblur.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.wiriteMessage.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.wiriteMessage.pending>) {
        try {
          const { dialogId, message } = action.payload;
          const session = getSession();
          const { writeMessage = {} } = session;

          if (!message.trim()) {
            delete writeMessage[dialogId];
          } else {
            writeMessage[dialogId] = message;
          }

          setSession({
            writeMessage,
          });

          yield put(setters.setWriteMessage(writeMessage));

          yield put(actions.postDialogMessageUnblur.fulfilled());
        } catch (error) {
          yield put(actions.postDialogMessageUnblur.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.addCustomBot.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.addCustomBot.pending>) {
        // yield put(setters.setBot(action.payload));
        // yield put(setters.setSetupCategories());
      }
    ),
    takeLatest(
      actions.getBotSearch.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getBotSearch.pending>) {
        try {
          yield delay(1000);
          const search: IResponse<botService.IPostBotSearchData> = yield call(
            botService.postBotSearch,
            action.payload
          );

          yield put(setters.setBotSearch(search.data));

          analyticsEvents.searchInitiated({
            searched_words: action.payload.nickname,
          });

          yield put(actions.getBotSearch.fulfilled());
        } catch (error) {
          yield put(actions.getBotSearch.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.postBotDialog.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postBotDialog.pending>) {
        try {
          const botDialog: IResponse<botService.IPostBotDialogData> =
            yield call(botService.postBotDialog, action.payload);

          //@ts-ignorec
          yield put(setters.setBot(botDialog.data));

          yield put(actions.postBotDialog.fulfilled());
          yield call(dialogService.postDialogVisit, {
            target_user_id: action.payload.bot_chat_user_id,
          });
        } catch (error) {
          yield put(actions.postBotDialog.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getBotRecent.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getBotRecent.pending>) {
        try {
          const botRecent: IResponse<botService.IGetBotRecentData> = yield call(
            botService.getBotRecent,
            action.payload
          );
          console.debug(action.payload.limit, botRecent.data.length);
          if (action.payload.offset) {
            yield put(
              setters.setBotRecentAppend({
                items: botRecent.data,
                hasMore: action.payload.limit === botRecent.data.length,
              })
            );
          } else {
            yield put(
              setters.setBotRecent({
                items: botRecent.data,
                hasMore: action.payload.limit === botRecent.data.length,
              })
            );
          }

          yield put(actions.getBotRecent.fulfilled());
        } catch (error) {
          yield put(actions.getBotRecent.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getCategories.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getCategories.pending>) {
        try {
          const botCategories: IResponse<botService.IGetCategoriesData> =
            yield call(botService.getCategories, action.payload);

          yield put(setters.setCategories(botCategories.data.data));

          yield put(actions.getCategories.fulfilled());
        } catch (error) {
          yield put(actions.getCategories.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.postDialogClear.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postDialogClear.pending>
      ) {
        try {
          yield call(dialogService.postDialogClear, action.payload);
          yield put(setters.setDialogClear(action.payload));

          yield put(actions.postDialogClear.fulfilled());
        } catch (error) {
          yield put(actions.postDialogClear.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.postDialogRemove.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postDialogRemove.pending>
      ) {
        try {
          yield call(dialogService.postDialogRemove, action.payload);
          yield put(setters.setDialogClear(action.payload));

          yield put(actions.postDialogRemove.fulfilled());
        } catch (error) {
          yield put(actions.postDialogRemove.rejected(error as Error));
        }
      }
    ),

    takeEvery(
      actions.postImageGalleryUnblur.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postImageGalleryUnblur.pending>
      ) {
        try {
          const { image_gallery_id } = action.payload;

          const imageGalleryUnblur: IResponse<dialogService.IPostImageGalleryUnblurData> =
            yield call(dialogService.postImageGalleryUnblur, {
              image_gallery_id,
            });

          yield put(
            accountActions.changeWalletBalance({
              balance: imageGalleryUnblur.data.balance,
            })
          );

          yield put(setters.setImageGalleryUnblur({ image_gallery_id }));

          yield put(actions.postImageGalleryUnblur.fulfilled());
        } catch (error) {
          yield put(actions.postImageGalleryUnblur.rejected(error as Error));
        }
      }
    ),
    takeEvery(
      actions.postDialogMessageFeedback.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postDialogMessageFeedback.pending>
      ) {
        try {
          const {
            message_id,
            themes,
            feedback_text,
            feedback_type,
            message_type,
            themesNames,
          } = action.payload;

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const messageFeedback: IResponse<dialogService.IPostDialogMessageFeedbackData> =
            yield call(dialogService.postDialogMessageFeedback, {
              message_id,
              themes,
              feedback_text,
              feedback_type,
            });

          analyticsEvents.reactionSent({
            message_kind: message_type,
            reaction_type: feedback_type === 'like' ? 'upvote' : 'downvote',
            feedback: feedback_text,
            labels: themesNames,
          });
          yield put(
            setters.setMessageFeedback({ message_id, feedback: feedback_type })
          );

          yield put(actions.postDialogMessageFeedback.fulfilled());
        } catch (error) {
          yield put(actions.postDialogMessageFeedback.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.deleteBot.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.deleteBot.pending>) {
        try {
          yield call(botService.deleteBot, action.payload);
          // yield put(setters.deleteBot(action.payload));

          yield put(actions.deleteBot.fulfilled());
        } catch (error) {
          yield put(actions.deleteBot.rejected(error as Error));
        }
      }
    ),

    takeLatest(
      actions.getBotsPriorityList.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getBotsPriorityList.pending>
      ) {
        try {
          const params = {
            limit: 20,
            offset: 0,
            ...action.payload,
          };
          const botsPriorityList: IResponse<botService.IGetBotsPriorityListData> =
            yield call(botService.getBotsPriorityList, params);

          yield put(
            setters.setBotsPriorityList({
              items: botsPriorityList.data.data,
              ...params,
            })
          );

          yield put(actions.getBotsPriorityList.fulfilled());
        } catch (error) {
          yield put(actions.getBotsPriorityList.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getBotsPriorityListMore.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getBotsPriorityListMore.pending>
      ) {
        try {
          const botsPriorityListState: moduleState.IStateData['botsPriorityList'] =
            yield select(selectors.botsPriorityList);

          const botsPriorityListParams = {
            categories: botsPriorityListState.categories,
            limit: botsPriorityListState.limit,
            offset: botsPriorityListState.offset + botsPriorityListState.limit,
          };
          const botsPriorityList: IResponse<botService.IGetBotsPriorityListData> =
            yield call(botService.getBotsPriorityList, botsPriorityListParams);

          yield put(
            setters.setBotsPriorityList({
              items: [
                ...botsPriorityListState.items,
                ...botsPriorityList.data.data,
              ],
              ...botsPriorityListParams,
            })
          );

          yield put(actions.getBotsPriorityListMore.fulfilled());
        } catch (error) {
          yield put(actions.getBotsPriorityListMore.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.getMyBots.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getMyBots.pending>) {
        try {
          const params = {
            limit: 20,
            offset: 0,
            ...action.payload,
          };
          const myBots: IResponse<botService.GetMyBotsData> = yield call(
            botService.getMyBots,
            params
          );

          yield put(
            setters.setMyBots({
              items: myBots.data,
              ...params,
            })
          );

          yield put(actions.getMyBots.fulfilled());
        } catch (error) {
          yield put(actions.getMyBots.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getMyBotsMore.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getMyBotsMore.pending>) {
        try {
          const myBotsState: moduleState.IStateData['myBots'] = yield select(
            selectors.botsPriorityList
          );

          const botsPriorityListParams = {
            limit: myBotsState.limit,
            offset: myBotsState.offset + myBotsState.limit,
          };
          const myBots: IResponse<botService.GetMyBotsData> = yield call(
            botService.getMyBots,
            botsPriorityListParams
          );

          yield put(
            setters.setMyBots({
              items: [...myBotsState.items, ...myBots.data],
              ...botsPriorityListParams,
            })
          );

          yield put(actions.getMyBotsMore.fulfilled());
        } catch (error) {
          yield put(actions.getMyBotsMore.rejected(error as Error));
        }
      }
    ),
  ]);
}
