import React from 'react';
import classNames from 'classnames/bind';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import { setSession, getSession } from 'src/services/RomanticAI/session';
import { Button } from '../Button';
import * as clientEvents from 'src/services/RomanticAI/client-events';

import styles from './Unboarding.module.scss';
import { useAccountSelect } from 'src/hooks/useAccount';
import 'swiper/css';

const sx = classNames.bind(styles);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUboardingProps {}

export const Unboarding = (props: IUboardingProps) => {
  const user = useAccountSelect.user();
  const [selectedSlide, setSelectedSlide] = React.useState(0);
  const [swiper, setSwiper] = React.useState<SwiperClass | undefined>();

  const [isHideUnboarding, setHideUboarding] = React.useState(true);

  React.useEffect(() => {
    if (isHideUnboarding) {
      if (
        user?.video_onboarding === 'new' &&
        user?.is_adult_confirm === false
      ) {
        const session = getSession();
        if (!session.unboadring) {

          setHideUboarding(false);
        }
      }
    }
  }, [isHideUnboarding, user]);

  const slides = React.useMemo(() => new Array(6).fill(null), []);

  const handleNext = () => {
    if (swiper) {
      if (selectedSlide === slides.length - 1) {
        clientEvents.onboardingCompleted({ value: true})
        setHideUboarding(true);
        setSession({ unboadring: true });
      } else {
        swiper.slideNext();
      }
    }
  };

  return (

    <>
      {!isHideUnboarding && (
        <div className={sx('container')}>
          <div className={sx('slider')}>
            <Swiper
              spaceBetween={0}
              noSwiping={true}
              noSwipingClass={'swiper-slide'}
              initialSlide={0}
              onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
              onSlideChange={(swiper: SwiperClass) => {
                setSelectedSlide(swiper.activeIndex);
              }}
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={0} virtualIndex={0} className={sx('slide')}>
                  <div className={sx('vidio-container')}>
                    <video
                      autoPlay
                      muted
                      width={'100%'}
                      height={'100%'}
                      // controls
                      loop
                      className={sx('vidio')}
                      poster={`/assets/unboarding/preview/${index + 1}.webp`}
                    >
                      <source
                        src={`https://alkdksmkvmrvm.s3.amazonaws.com/videos/onboarding/screen_${
                          index + 1
                        }.mp4`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className={sx('text')}>
                    <img
                      src={`/assets/unboarding/text/TextOverlay_Screen_${
                        index + 1
                      }.png`}
                      alt=""
                    ></img>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={sx('actions')}>
            <Button
              className={sx('next')}
              fuild
              variant="outlined"
              color="primary"
              onClick={handleNext}
              key={selectedSlide}
            >
              Continue
            </Button>
          </div>
          <div className={sx('thumbs')}>
            {slides.map((slide, index) => (
              <div
                className={sx('thumbs-circle', {
                  'thumbs-circle-active': index <= selectedSlide,
                })}
              ></div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
