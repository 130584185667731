import { http } from 'src/services/RomanticAI/http';
import * as types from './types';

export const getDialogCategorized = () =>
  http.get<types.IGetDialogCategorizedData>('/dialog/categorized');

export const getDialogs = () =>
  http.get<types.IGetDialogCategorizedData>('/dialog');

export const getDialogHistory = ({
  dialogId,
  ...params
}: types.IGetDialogHistoryParams) =>
  http.get<types.IGetDialogHistoryData>(`/dialog/${dialogId}/history`, {
    params,
  });


export const postDialogMessage = ({
  dialogId,
  ...data
}: types.IPostDialogMessageParams) =>
  http.post<types.IPostDialogMessageData>(`/dialog/${dialogId}/message`, data);


export const postDialogMessageUnblur = ({
  messageId,
}: types.IPostDialogMessageUnblurParams) =>
  http.post<types.IPostDialogMessageUnblurData>(`/message/${messageId}/unblur`);

  export const postDialogMessageFeedback = ({
    message_id,
    ...data
  }: types.IPostDialogMessageFeedbackParams) =>
    http.post<types.IPostDialogMessageFeedbackData>(`/message/${message_id}/feedback`, data);


  export const postImageGalleryUnblur = ({
    image_gallery_id,
  }: types.IPostImageGalleryUnblurParams) =>
    http.post<types.IPostImageGalleryUnblurData>(`/image-gallery/${image_gallery_id}/unblur`);

export const postDialogVisit = ({
  target_user_id,
  ...data
}: types.IPostDialogVisitParams) =>
  http.post<types.IPostDialogVisitData>(`/dialog/${target_user_id}/is_visit`, data);

  export const postDialogClear = ({
    dialogId,
    ...data
  }: types.IPostDialogClearParams) =>
    http.post(`/dialog/${dialogId}/clear`, data);

  export const postDialogRemove = ({
    dialogId,
    ...data
  }: types.IPostDialogDeleteParams) =>
    http.post(`/dialog/${dialogId}/delete`, data);

export * from './types';
