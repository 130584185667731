import React from 'react';
import classNames from 'classnames/bind';
import { Icon } from 'src/components/Icon';
import styles from './AppInstall.module.scss';
import { Button } from '../Button';
import { useModalAppInstall } from 'src/hooks/route/useModalAppInstall';

const cn = classNames.bind(styles);

export const AppInstall = () => {
  const modalAppInstall = useModalAppInstall()

  if (!modalAppInstall.isNeedInstall) return <></>

  return (
    <div className={cn('container')}>

      <div className={cn('main')}>
        <Icon name='AppInstallChat' />

        <div className={cn('title')}>
          Don't miss messages from AI Girlfriend
        </div>
      </div>
      <Button
        variant='filled'
        fuild
        color='primary'
        size='md'
        onClick={()=>modalAppInstall.open()}
      >
        Get the app
      </Button>


    </div>
  )
}