import { IGlobalStore } from 'src/types/store';
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux';
import { actions as sagaActions, PREFIX } from './actions';

import * as dialogService from 'src/services/RomanticAI/dialog/types';
import * as botService from 'src/services/RomanticAI/bot/types';

import * as utils from './utils';
import { getSession } from 'src/services/RomanticAI/session';

export interface IStateData {

  bot: botService.IBot
  botsPriorityList: {
    items:botService.IGetBotsPriorityListData['data'],
    limit: number;
    offset: number;
    categories?:string[];
  };
  myBots: {
    items:botService.GetMyBotsData,
    limit: number;
    offset: number;
  };
  dialogs: dialogService.IGetDialogsData
  dialogHistory: dialogService.IGetDialogHistoryData & {hasMore?:boolean};
  highDemand: ReturnType<typeof utils.getHighDemand>;
  isHighDemandAlertOpen: boolean;
  writeMessage: Record<string, string>;
  isTyping?: boolean;
  botSearch: botService.IPostBotSearchData;
  botRecent: {
    hasMore?: boolean;
    items: botService.IGetBotRecentData
  }
  categories: botService.IGetCategoriesData['data']
}

export interface IState {
  data: IStateData;
  communication: TStateCommunications<typeof sagaActions>;
}

export const getInitialData = (): IStateData => {
  const session = getSession();

  return {

    botsPriorityList:{
      items:[],
      limit: 0,
      offset: 0,
      categories:undefined,
    },
    myBots:{
      items:[],
      limit: 0,
      offset: 0,
    },
    isTyping: false,
    bot: {
      id: 0,
      nickname: '',
      first_name: '',
      name:'',
      last_name: '',
      gender: '',
      age: 0,
      country: '',
      city: '',
  is_user_owner:false,
      description: '',
      traits: [],
      media:[],
      new_custom_traits:[],
      mood: {
        shyness: 0,
        optimism: 0,
        ordinariness: 0
      }
    },
    dialogHistory: {
      id: 0,
      count: 0,
      filters: {
        count: 0,
        from_message_id: 0,
        reverse: false,
      },
      messages: []
    },
    highDemand: utils.getHighDemand(),
    isHighDemandAlertOpen: false,
    writeMessage: session.writeMessage || {},
    dialogs: [],
    botSearch: {
      data: [],
      offset: 0,
      limit: 0,
    },
    botRecent: {
      hasMore: false,
      items: []
    },
    categories: []

  };
};

export const initialState: IState = {
  data: getInitialData(),
  communication: createStateSagaCommunications(sagaActions),
};

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState;
};

export { PREFIX };
