import React from 'react';
import classNames from 'classnames/bind';


import { Button } from 'src/components/Button';
import { Icon } from 'src/components/Icon';
import { useModalPaywallHeart, ECoinsPaywallReason } from 'src/hooks/route/useModalPaywallHeart';
import { useAccountSelect } from 'src/hooks/useAccount';
import CountUp from "react-countup";
import styles from './WalletButton.module.scss';
import { useSearchQuery } from 'src/hooks/useSearchQuery';

const sx = classNames.bind(styles)

export {
  ECoinsPaywallReason
}
export interface IWalletButtonProps {
  reason: ECoinsPaywallReason
}

export const WalletButton = (props: IWalletButtonProps) => {
  const { reason } = props
  const { open } = useModalPaywallHeart()
  const pricingRules = useAccountSelect.pricingRules()
  const wallet = useAccountSelect.wallet();
  const isSubscribled = useAccountSelect.isSubscribled();
  const { setQuery} = useSearchQuery()
  const [countStart, setCountStart] = React.useState(wallet?.balance);

  const isHeartsOnlySubscribed = pricingRules?.flow === 'old' ? true: false;

  React.useEffect(()=>{
      return ()=>setCountStart(wallet?.balance)
  },[wallet?.balance])

  const handleOpen = ()=>{
    if (isHeartsOnlySubscribed && !isSubscribled){
      setQuery({
        modal: 'paywall-subscription',
        reason: 'self_heart_ballance',
      });
    } else {
      open({ reason: reason })
    }
  }

  return (
    <Button
      className={sx('wallet')}
      color="secondary"
      variant="filled"
      size="md"
      onClick={handleOpen}
      style={{
        width: wallet?.balance !==undefined?
        `${wallet.balance.toString().length * 10 + 60}px`:
        undefined
      }}
    >
      <Icon name="Heart" />
      {wallet?.balance !== undefined && (
        <CountUp
          duration={1}
          className="counter"
          start={countStart}
          end={wallet?.balance}
        />
      )}
    </Button>

  )
}