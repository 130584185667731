import { createSelector } from 'reselect';
import { createCommunicationSelectors } from 'src/helpers/redux';
import { IState, PREFIX, TGlobalStateWithService } from './state';
import { actions } from './actions';
import { format } from 'date-fns';
import { IMessage } from 'src/services/RomanticAI/dialog';

export const selectData = (state: TGlobalStateWithService): IState['data'] =>
  state[PREFIX].data;

export const selectCommunication = (
  state: TGlobalStateWithService
): IState['communication'] => state[PREFIX].communication;

export const communications = createCommunicationSelectors<
  TGlobalStateWithService,
  IState['communication'],
  typeof actions
>(selectCommunication, actions);

const dialogHistoryMessages = createSelector(selectData, (state) => state.dialogHistory.messages)
export const selectors = {
  bot:  createSelector(selectData, (state) => state.bot),
  isTyping: createSelector(selectData, (state) => state.isTyping),
  dialogs: createSelector(selectData, (state) => state.dialogs),
  dialogHistory: createSelector(selectData, (state) => state.dialogHistory),
  dialogHistoryImages: createSelector(selectData, (state) => state.dialogHistory.messages?.filter((message)=>message.type === 'img')),
  messagesTimelineList: createSelector(dialogHistoryMessages, (messages) => {

    const timeline =  messages.reduce((timeline, message, index)=>{
      const date = message.date? format(new Date(message.date),'MMMM d') : ''
      if (timeline[date]){
        timeline[date].push(message);
      } else {
        timeline[date] = [message]
      }

      return timeline;
    },{} as Record<string,IMessage[]>)

    return Object.entries(timeline).map(([date,messages])=>{
      return {
        date: date,
        label: date,
        messages
      }
    })
  }),
  highDemand: createSelector(selectData, (state) => state.highDemand),
  isHighDemandAlertOpen: createSelector(selectData, (state) => state.isHighDemandAlertOpen),
  writeMessage: createSelector(selectData, (state) => state.writeMessage),
  botSearch: createSelector(selectData, (state) => state.botSearch),
  categories: createSelector(selectData, (state) => {

    return [...state.categories].sort((a,b)=>b.priority - a.priority)
  }),
  botsPriorityList: createSelector(selectData, (state) => state.botsPriorityList),
  botRecent: createSelector(selectData, (state) => state.botRecent),
  myBots: createSelector(selectData, (state) => state.myBots),
};
