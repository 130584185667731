import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import {
  builderSagaCommunications,
  clearAllStateCommunications,
} from 'src/helpers/redux';
import { actions as moduleActions } from './actions';
import * as moduleState from './state';

import moduleSagas from './sagas';
import * as utils from './utils';

export const slice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    setDialogs(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['dialogs']>
    ) {
      state.data.dialogs = action.payload
    },
    setDialogHistory(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['dialogHistory']>
    ) {
      state.data.dialogHistory = action.payload
    },
    setDialogHistoryAppend(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['dialogHistory']>
    ) {
      state.data.dialogHistory = {
        ...state.data.dialogHistory,
        ...action.payload,
        messages: [...state.data.dialogHistory.messages, ...action.payload.messages,],
      }
    },
    setBotMessageTyping(state: moduleState.IState, action: PayloadAction<moduleState.IStateData['isTyping']>) {
      state.data.isTyping = action.payload
    },
    setClearGetDialogHistory(state: moduleState.IState,) {
      const initialState =  moduleState.getInitialData()
      state.data.bot = initialState.bot
      state.data.dialogHistory = initialState.dialogHistory
      state.communication.getDialogHistory.state = 0

    },
    setBot(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['bot']>
    ) {
      state.data.bot = action.payload;
    },

    setBotsPriorityList(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['botsPriorityList']>
    ) {
      state.data.botsPriorityList = action.payload;
    },
    setMyBots(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['myBots']>
    ) {
      state.data.botsPriorityList = action.payload;
    },
    setUserMessage(
      state: moduleState.IState,
      action: PayloadAction<{
        dialogId: moduleState.IStateData['dialogHistory']['id'];
        messages: moduleState.IStateData['dialogHistory']['messages'];
      }>
    ) {
      const { dialogId, messages } = action.payload;

      if (state.data.dialogHistory) {
        if (state.data.dialogHistory.id === dialogId) {
          state.data.dialogHistory.messages = [...messages, ...state.data.dialogHistory.messages];
        }
      }
    },
    setUserMessageUpdate(
      state: moduleState.IState,
      action: PayloadAction<{
        dialogId: moduleState.IStateData['dialogHistory']['id'];
        messages: moduleState.IStateData['dialogHistory']['messages'];
      }>
    ) {
      const { dialogId, messages } = action.payload;
      console.debug('setUserMessageUpdate')
      if (state.data.dialogHistory) {
        if (state.data.dialogHistory.id === dialogId) {
          state.data.dialogHistory.messages = state.data.dialogHistory.messages.map((message)=> {
            const updated = messages.find((updatedMessage)=> updatedMessage.id === message.id);

            if (updated){
              console.debug('UPDATED',{
                ...message,
                ...updated,
              })
              return {
                ...message,
                ...updated,
              }
            }

            return message
          });
        }
      }
    },
    setBotMessage(
      state: moduleState.IState,
      action: PayloadAction<{
        dialogId: moduleState.IStateData['dialogHistory']['id'];
        messages: moduleState.IStateData['dialogHistory']['messages'];
      }>
    ) {
      const { dialogId, messages } = action.payload;

      if (state.data.dialogHistory) {
        if (state.data.dialogHistory.id === dialogId) {
          state.data.dialogHistory.messages = [...messages, ...state.data.dialogHistory.messages.filter((message=>!messages.find((stateMessage)=>message.id === stateMessage.id)))];
        }
      }

      const botMessages = messages.filter(item =>
        item.type === 'img' &&
        dialogId === state.data.bot.id
      );
      if (botMessages.length) {
        const botMedia = botMessages.map((item): moduleState.IStateData['bot']['media'][number] => ({
          type: 'personal',
          blurred: item.blurred,
          message_id: item.id,
          image_url: item.url as string,
          photo_cost: item.unblur_cost as number,
          is_sexting_message: item.is_sexting_message
        }))
        state.data.bot = {
          ...state.data.bot,
          media: [
            ...botMedia,
            ...state.data.bot.media,
          ]
        }
      }
    },

    setAppendChatUserMessage(
      state: moduleState.IState,
      action: PayloadAction<{
        dialogId: moduleState.IStateData['dialogHistory']['id'];
        messages: moduleState.IStateData['dialogHistory']['messages'];
      }>
    ) {
      const { dialogId, messages } = action.payload;

      if (state.data.dialogHistory) {
        if (state.data.dialogHistory.id === dialogId) {
          state.data.dialogHistory.messages = [...messages, ...state.data.dialogHistory.messages];
        }
      }
    },
    setMessageUnblur(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['dialogHistory']['messages'][number]>
    ) {
      const { id, user } = action.payload;

      const unblurMessageMap = (
        message: moduleState.IStateData['dialogHistory']['messages'][number]
      ) => {
        if (message.id === id) {
          return {
            ...message,
            blurred: false,
            unblur_cost: null,
          };
        }
        return message;
      };

      if (state.data.dialogHistory) {
        if (state.data.dialogHistory.id === user.id) {
          state.data.dialogHistory.messages =
            state.data.dialogHistory.messages.map(unblurMessageMap);
        }
      }
      state.data.bot = {
        ...state.data.bot,
        media: state.data.bot.media?.map((item) => {
          if (item.message_id === id) {
            return {
              ...item,
              blurred: false
            }
          }

          return item
        })
      }
    },
    setMessageFeedback(
      state: moduleState.IState,
      action: PayloadAction<{ message_id: number; feedback: string }>
    ) {
      const { message_id, feedback } = action.payload;

      if (state.data.dialogHistory) {
        state.data.dialogHistory.messages =
          state.data.dialogHistory.messages.map((message) => {
            if (message.id === message_id) {
              return {
                ...message,
                feedback: feedback,
              }
            }
            return message
          });
      }

    },
    setImageGalleryUnblur(
      state: moduleState.IState,
      action: PayloadAction<{ image_gallery_id: number }>
    ) {
      state.data.bot = {
        ...state.data.bot,
        media: state.data.bot.media?.map((item) => {
          if (item.image_gallery_id === action.payload.image_gallery_id) {
            return {
              ...item,
              blurred: false
            }
          }

          return item
        })
      }
    },
    setHighDemand(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['highDemand']>
    ) {
      state.data.highDemand = action.payload;
    },

    setHighDemandAlertOpen(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['isHighDemandAlertOpen']>
    ) {
      state.data.isHighDemandAlertOpen = action.payload;
    },

    setWriteMessage(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['writeMessage']>
    ) {
      state.data.writeMessage = action.payload;
    },
    setBotSearch(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['botSearch']>
    ) {
      state.data.botSearch = action.payload;
    },
    setBotRecent(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['botRecent']>
    ) {
      state.data.botRecent = action.payload;
    },
    setBotRecentAppend(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['botRecent']>
    ) {
      const { hasMore, items } = action.payload
      state.data.botRecent.hasMore = hasMore;
      state.data.botRecent.items.push(...items)
    },
    setCategories(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['categories']>
    ) {
      state.data.categories = action.payload;
    },

    setDialogClear(
      state: moduleState.IState,
      action: PayloadAction<{ dialogId: number }>
    ) {
      const { dialogId } = action.payload

      if (state.data.dialogHistory.id === dialogId) {
        state.data.dialogHistory.messages = []
      }
      state.data.dialogs = state.data.dialogs.filter((dialog)=> dialog.id !== action.payload.dialogId)
    },
    setClear(state: moduleState.IState) {
      state.data = moduleState.getInitialData();
      clearAllStateCommunications(state.communication);
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions);
  },
});

export const setters = slice.actions;
export const actions = moduleActions;
export const reducers = { [moduleState.PREFIX]: slice.reducer };
export const sagas = moduleSagas;
export const state = moduleState;
export * from './selectors';
export * from './state';
