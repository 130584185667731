

export enum ClientEvents {
  /* после прохождения онбординга */
  onboarding_completed = 'rai_is_onboarding_completed',

  /*при открытии любого пейвола. Только 1 раз надо отправить при 1-м открытии пейвола.*/
  paywall_shown = 'rai_no_regular_paywall_shown'
}

export interface IPostClientEventParams<data extends object = object> {
  event: ClientEvents,
  data: data;
}

export interface IOnboardingCompletedData {
  value: boolean;
}

export interface IPaywallShownData {
  value: boolean;
}