import React from 'react';
import classNames from 'classnames/bind';
import { Icon } from 'src/components/Icon';
import styles from './SocialPanel.module.scss';
import { AffiliateButton } from '../AffiliateButton';

const cn = classNames.bind(styles);

export const SocialPanel = () => {

  return (
    <div className={cn('container')}>

      <div className={cn('main')}>
        <div className={cn('title')}>
          Join us
        </div>
        <a
          className={cn('social-button', 'button-discord')}
          href={window.settings.SOCIAL_DISCROD_LINK}
          target='_blank'
          rel="noreferrer"
        >
          <Icon name='Social/Discord' />
        </a>

        <a
          className={cn('social-button', 'button-reddit')}
          href={window.settings.SOCIAL_REDDIT_LINK}
          target='_blank'
          rel="noreferrer"
        >
          <Icon name='Social/Reddit' />
        </a>
      </div>

      <AffiliateButton fuild className={cn('affilateButton')}/>

      <div className={cn('footer')}>
        <a
          target="_blank"
          href={window.settings.APP_LEGAL_LINK}
          rel="noreferrer"
        >
          Terms and Policies
        </a>
      </div>
    </div>
  )
}


export const SocialProfilePanel = () => {

  return (
    <div className={cn('container', 'profile')}>

      <div className={cn('main')}>
        <div className={cn('title')}>
          Join us in social networks
        </div>
        <a
          className={cn('social-button', 'button-discord')}
          href={window.settings.SOCIAL_DISCROD_LINK}
          target='_blank'
          rel="noreferrer"
        >
          <Icon name='Social/Discord' />
        </a>

        <a
          className={cn('social-button', 'button-reddit')}
          href={window.settings.SOCIAL_REDDIT_LINK}
          target='_blank'
          rel="noreferrer"
        >
          <Icon name='Social/Reddit' />
        </a>
      </div>


    </div>
  )
}