import React from 'react';
import cn from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './Button.module.scss';



export enum EButtonColor {
  danger = 'danger',
  secondary = 'secondary',
  primary = 'primary',
  warning = 'warning',
}
export enum EButtonVariant {
  texted = 'texted',
  filled = 'filled',
  outlined = 'outlined',
}
export enum EButtonSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: `${EButtonColor}`;
  variant?: `${EButtonVariant}`;
  size?: `${EButtonSize}`;
  fuild?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export const Button = (props: IButtonProps) => {
  const {
    children,
    variant = EButtonVariant.texted,
    color = EButtonColor.secondary,
    size = EButtonSize.md,
    fuild,
    loading,
    ...attrs
  } = props;

  return (
    <button

      {...attrs}
      disabled={attrs.disabled || loading}
      className={cn(
        styles.container,
        styles[variant],
        styles[`${variant}-${color}`],
        styles[size],
        {
          [styles.fuild]: fuild,
          [styles.disabled]: attrs.disabled
        },
        attrs.className,
      )}
    >
      {
        loading ? <CircularProgress className={cn(styles.loading)} /> : children
      }
    </button>
  );
};
