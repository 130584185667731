import {
  call,
  all,
  put,
  takeLeading,
  delay,
  takeLatest,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { ActionPalyoad } from 'src/helpers/redux';
import {
  gtag,
  googleTagManagerConsentGranted,
} from 'src/services/analytics/providers/GoogleTagManager';
import {
  facebookPixel,
  facebookPixelInitialize,
} from 'src/services/analytics/providers/FacebookPixel';
import { posthogInitialize } from 'src/services/analytics/providers/Posthog';
import { firebaseInitialize } from 'src/services/RomanticAI/firebase';
import { IResponse } from 'src/services/RomanticAI/http';
import * as firebaseService from 'src/services/RomanticAI/firebase';
import * as userService from 'src/services/RomanticAI/user';
import * as botService from 'src/services/RomanticAI/bot';
import * as sessionService from 'src/services/RomanticAI/session';
import * as socketService from 'src/services/socket';
import * as paymentService from 'src/services/RomanticAI/payment';
import { ISuperOffer } from 'src/services/RomanticAI/session';
import { analyticsEvents } from 'src/services/analytics/events';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { posthog } from 'src/services/analytics/providers/Posthog';
import { ESubscriptionReason } from 'src/services/analytics/events';
// import { actions as dialogActions } from 'src/redux/modules/dialog/actions';
import { actions } from './actions';
import { selectors } from './selectors';
import { IStateData, setters } from './index';
import { isPWAInstalled } from 'src/utils/isPWAInstalled';
import platform from 'platform';
import { jwtDecode } from 'jwt-decode';

facebookPixelInitialize();
firebaseInitialize();
posthogInitialize();

export default function* sagas() {
  yield all([
    takeLeading(
      actions.appReady.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.appReady.pending>) {
        try {
          yield call(userService.postAppReady);
          yield put(actions.appReady.fulfilled());
        } catch (error) {
          yield put(actions.appReady.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.sessionSetup.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.sessionSetup.pending>) {
        try {
          yield put(setters.setClear());

          let state: IStateData = yield select(selectors.data);

          if (state.firebaseIdToken) {
            const firebaseIdToken: string = yield call(
              firebaseService.getIdToken,
              true
            );

            yield put(setters.setFirebaseIdToken(firebaseIdToken));
            state = yield select(selectors.data);
          }

          if (state.firebaseIdToken && !state.jwt?.refresh_token) {
            const { analyticsUTM } = sessionService.getSession();

            const userLoginData: IResponse<userService.IUserLoginData> =
              yield call(userService.userLogin, {
                flow: 'firebase',
                client_parameters: analyticsUTM,
                flow_parameters: {
                  firebase_token: state.firebaseIdToken,
                },
              });

            const { exp: expiration } = jwtDecode<{ exp: number }>(
              userLoginData.data.access_token
            );
            yield put(
              setters.setJWT({
                access_token: userLoginData.data.access_token,
                refresh_token: userLoginData.data.refresh_token,
                uuid: userLoginData.data.uuid,
                updatedAt: new Date().getTime(),
                expiration,
              })
            );

            const user: IResponse<userService.IGetUserData> = yield call(
              userService.getUser
            );
            // if (state.clientToken !== user.data.uuid) {
            //   yield put(setters.setAuthClientToken(state.clientToken));
            // }
            yield put(setters.setUser(user.data));
            yield put(actions.getSubscription.pending());
            yield put(actions.getWallet.pending());

            socketService.connectNamespaces(user.data.uuid);

            state = yield select(selectors.data);
            const session = sessionService.getSession();
            // yield call(
            //   userService.pathUser,
            //   {
            //     is_adult_confirm: isConfirm,
            //     query: session.analyticsUTM
            //   }
            // );
          }

          if (state.firebaseIdToken && state.jwt?.refresh_token) {
            try {
              socketService.connectNamespaces(state.jwt.uuid);

              yield put(actions.accountSetup.pending());

              state = yield select(selectors.data);
            } catch {
              yield put(actions.logout.pending());

              return;
            }
          }

          if (state.firebaseIdToken && state.jwt?.refresh_token) {
            yield put(actions.analyticsUserAuth.pending());
            yield put(actions.analyticUserProperties.pending());
            const currentUser = firebaseService?.app.auth?.currentUser;
            const userProvider = firebaseService.getUserProvider();
            if (currentUser) {
              const { photoURL, displayName, email } = currentUser;
              yield put(
                setters.setProfile({
                  provider: userProvider,
                  displayName: displayName || '',
                  email: email || '',
                  photoUrl: photoURL || '',
                })
              );
            }
          }

          yield put(actions.sessionSetup.fulfilled());
        } catch (error) {
          sessionService.clearSession();
          window.location.reload();
          yield put(actions.sessionSetup.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.accountSetup.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.accountSetup.pending>) {
        try {
          const user: IResponse<userService.IGetUserData> = yield call(
            userService.getUser
          );

          yield put(setters.setUser(user.data));
          yield put(actions.getSubscription.pending());
          yield put(actions.getWallet.pending());

          if (user.data.pricing_rules?.flow === 'new_pricing') {
            yield put(actions.getAppDailyReward.pending());
          }

          yield put(actions.analyticsUserAuth.pending());
          yield put(actions.analyticUserProperties.pending());

          yield put(actions.accountSetup.fulfilled());
        } catch (error) {
          yield put(actions.accountSetup.rejected(error as Error));
        }
      }
    ),

    takeLatest(
      actions.getClientEmail.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getClientEmail.pending>) {
        try {
          yield put(actions.getClientEmail.fulfilled());
          const email: IResponse<{ email: string }> = yield call(
            userService.getEmail
          );
          yield put(setters.setSubscriptionEmail(email.data.email));
        } catch (error) {
          yield put(actions.getClientEmail.rejected(error as Error));
        }
      }
    ),

    takeLatest(
      actions.sessionRefresh.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.sessionRefresh.pending>) {
        try {
          const session = sessionService.getSession();

          if (!session.firebaseIdToken || !session.jwt?.refresh_token) {
            yield put(actions.sessionRefresh.fulfilled());
            return;
          }

          const result: firebaseService.IdTokenResult = yield call(
            firebaseService.getIdTokenResult,
            true
          );

          if (!result?.expirationTime) {
            yield put(actions.sessionRefresh.fulfilled());
            return;
          }

          const currentTime = new Date().getTime();
          const expirationTime = new Date(result.expirationTime).getTime();
          const minute = 1000 * 60;
          const timerTime = expirationTime - currentTime - minute;

          if (timerTime) {
            yield delay(timerTime);
            yield put(actions.sessionRefresh.pending());
          }

          yield put(actions.sessionRefresh.fulfilled());
        } catch (error) {
          yield put(actions.sessionRefresh.rejected(error as Error));
        }
      }
    ),
    takeLatest(
      actions.sessionRefreshJwt.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.sessionRefresh.pending>) {
        try {
          const state: IStateData = yield select(selectors.data);

          if (!state.jwt?.refresh_token) {
            yield put(actions.sessionRefresh.fulfilled());
            return;
          }

          const refreshTokens: IResponse<userService.IUserRefreshTokensData> =
            yield call(userService.userRefreshTokens, {
              refresh_token: state.jwt.refresh_token,
            });

          const { exp: expiration } = jwtDecode<{ exp: number }>(
            refreshTokens.data.refresh_token
          );

          yield put(
            setters.setJWT({
              ...state.jwt,
              access_token: refreshTokens.data.access_token,
              refresh_token: refreshTokens.data.refresh_token,
              updatedAt: new Date().getTime(),
              expiration,
            })
          );

          yield put(actions.sessionRefreshJwt.fulfilled());
        } catch (error) {
          yield put(actions.sessionRefreshJwt.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.confirmAdult.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.confirmAdult.pending>) {
        try {
          const { isConfirm, isCoockieConfirm } = action.payload;


          sessionService.setSession({ confirmAdult: isConfirm });

          yield put(setters.setConfirmAdult(isConfirm));

          googleTagManagerConsentGranted();
          facebookPixelInitialize();
          firebaseInitialize();
          posthogInitialize();

          analyticsEvents.consentGranted({
            cookies_consent: isCoockieConfirm,
            ai_18_consent: isConfirm,
          });

          yield put(actions.confirmAdult.fulfilled());
        } catch (error) {
          yield put(actions.confirmAdult.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.auth.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.auth.pending>) {
        try {
          const { provider } = action.payload;

          yield call(firebaseService.auth, provider);
          yield put(actions.auth.fulfilled());
        } catch (error) {
          console.error(error);
          yield put(actions.auth.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.authStateChanged.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.authStateChanged.pending>
      ) {
        try {
          const currentUser = firebaseService?.app.auth?.currentUser;
          const userProvider = firebaseService.getUserProvider();
          let firebaseIdToken = '';

          try {
            firebaseIdToken = yield call(firebaseService.getIdToken, false);
          } catch (e) {
            yield put(actions.logout());
            return;
          }

          if (!firebaseIdToken) {
            yield put(actions.logout());
            return;
          }

          if (currentUser) {
            // If enable  need fiexed this:

            // [Bug][Web] Error screen on “back” button after email registration
            // http://gitlab-chat-01.aicht/ai-chat/ai-chat-api/-/issues/1308

            // [Bug][Web] Fix apple id authorization
            // http://gitlab-chat-01.aicht/ai-chat/ai-chat-api/-/issues/1307

            // if (currentUser.emailVerified === false) {
            //   yield put(setters.emailVerification({
            //     email: currentUser.email || '',
            //     required: true,
            //   }))
            //   yield put(actions.authStateChanged.fulfilled());

            //   return;
            // } else {
            setters.emailVerification(undefined);
            // }

            const { photoURL, displayName, email } = currentUser;

            yield put(
              setters.setProfile({
                provider: userProvider,
                displayName: displayName || '',
                email: email || '',
                photoUrl: photoURL || '',
              })
            );

            yield put(actions.analyticUserProperties.pending());

            // if (!state.firebaseIdToken) {
            yield put(setters.setFirebaseIdToken(firebaseIdToken));
            yield put(actions.sessionSetup.pending());
            // }
          }

          yield put(actions.authStateChanged.fulfilled());
        } catch (error) {
          yield put(actions.logout());
          yield put(actions.authStateChanged.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.logout.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.logout.pending>) {
        try {
          const state: IStateData = yield select(selectors.data);
          yield call(firebaseService.signOut);
          yield put(setters.setFirebaseIdToken(''));
          yield put(
            setters.setProfile({
              displayName: '',
              email: '',
              photoUrl: '',
            })
          );
          yield put(setters.setSubscription(undefined));
          yield put(setters.setWallet(undefined));
          yield put(setters.setAppDailyReward(undefined));
          yield put(setters.setFirebaseIdToken(undefined));
          sessionService.clearSession();
          yield put(setters.setJWT(undefined));
          yield put(setters.setClientToken(state.authClientToken));

          yield put(actions.analyticUserProperties.pending());
          yield put(actions.logout.fulfilled());

          yield put(actions.sessionSetup.pending());
        } catch (error) {
          yield put(actions.logout.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.signIn.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.signIn.pending>) {
        try {
          yield call(firebaseService.signInEmailPassword, action.payload);
          yield put(actions.signIn.fulfilled());
        } catch (error) {
          yield put(actions.signIn.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.signUp.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.signUp.pending>) {
        try {
          yield call(firebaseService.signUpEmailPassword, action.payload);
          // yield put(actions.sendEmailVerification.pending())
          yield put(actions.signUp.fulfilled());
        } catch (error) {
          yield put(actions.signUp.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.sendEmailVerification.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.signUp.pending>) {
        try {
          const currentUser = firebaseService?.app.auth?.currentUser;
          const url = `${location.origin}/?modal=email-verification&status=success`;
          yield call(firebaseService.sendEmailVerification, url);

          yield put(actions.sendEmailVerification.fulfilled());
        } catch (error) {
          yield put(actions.sendEmailVerification.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.signInEmailLink.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.signInEmailLink.pending>
      ) {
        try {
          yield call(firebaseService.signInEmailLink);
          yield put(actions.signInEmailLink.fulfilled());
        } catch (error) {
          yield put(actions.signInEmailLink.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.recoveryPassword.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.recoveryPassword.pending>
      ) {
        try {
          yield call(firebaseService.resetPasswordLink, action.payload);
          yield put(actions.recoveryPassword.fulfilled());
        } catch (error) {
          console.debug(error);
          yield put(actions.recoveryPassword.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.sendChangePasswordLink.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.sendChangePasswordLink.pending>
      ) {
        try {
          yield call(firebaseService.sendChangePasswordLink);
          yield put(actions.sendChangePasswordLink.fulfilled());
        } catch (error) {
          console.debug(error);
          yield put(actions.sendChangePasswordLink.rejected(error as Error));
        }
      }
    ),
    // takeLeading(
    //   actions.changePassword.pending.toString(),
    //   function* (action: ActionPalyoad<typeof actions.changePassword.pending>) {
    //     try {
    //       yield call(firebaseService.changePassword, action.payload);
    //       yield put(actions.changePassword.fulfilled());
    //     } catch (error) {
    //       console.debug(error)
    //       yield put(actions.changePassword.rejected(error as Error));
    //     }
    //   }
    // ),
    takeLeading(
      actions.deleteAccount.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.deleteAccount.pending>) {
        try {
          analyticsEvents.deleteProfile({});
          try {
            yield call(firebaseService.deleteAccount, action.payload);
          } catch (e) {}
          try {
            yield call(userService.deleteUser);
          } catch (e) {}

          yield put(setters.setFirebaseIdToken(''));
          yield put(
            setters.setProfile({
              displayName: '',
              email: '',
              photoUrl: '',
            })
          );

          yield put(setters.setSubscription(undefined));
          yield put(setters.setWallet(undefined));

          sessionService.clearSession();

          yield put(actions.deleteAccount.fulfilled());
          yield put(actions.analyticUserProperties.pending());
          yield put(actions.sessionSetup.pending());
        } catch (error) {
          yield put(actions.deleteAccount.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.updateEmail.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.updateEmail.pending>) {
        try {
          const { email } = action.payload;

          yield call(firebaseService.updateEmail, email);
          yield put(actions.analyticUserProperties.pending());
          yield put(actions.updateEmail.fulfilled());
        } catch (error) {
          yield put(actions.updateEmail.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.updateProfile.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.updateProfile.pending>) {
        try {
          const { displayName, photoUrl } = action.payload;

          yield call(firebaseService.updateProfile, {
            displayName,
            photoURL: photoUrl,
          });

          if (displayName) {
            yield put(setters.setProfile({ displayName }));
            sessionService.setSession({ displayName });
          }

          if (photoUrl) {
            yield put(setters.setProfile({ photoUrl }));
            sessionService.setSession({ photoUrl });
          }

          yield put(actions.updateProfile.fulfilled());
        } catch (error) {
          yield put(actions.updateProfile.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.uploadAvatar.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.uploadAvatar.pending>) {
        try {
          const { file } = action.payload;

          const result: { photoURL: string } = yield call(
            firebaseService.uploadAvatar,
            { file }
          );
          yield put(
            actions.updateProfile.pending({ photoUrl: result.photoURL })
          );
          yield put(actions.uploadAvatar.fulfilled());
        } catch (error) {
          yield put(actions.uploadAvatar.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getSubscription.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getSubscription.pending>
      ) {
        try {
          const { payload } = action;

          if (payload?.subscription) {
            yield put(setters.setSubscription(payload.subscription));
            const session = sessionService.getSession();

            sessionService.setSession({
              subscription: payload.subscription,
              isFirstSubscription: !session.subscription,
            });

            yield put(setters.setSubscription(payload.subscription));
          }

          const subcription: IResponse<paymentService.IGetSubscriptionData> =
            yield call(paymentService.getSubscription);

          yield put(setters.setSubscription(subcription.data));

          const session = sessionService.getSession();

          sessionService.setSession({
            subscription: subcription.data,
            isFirstSubscription: !session.subscription,
          });

          yield put(actions.analyticUserProperties.pending());
          yield put(actions.getSubscription.fulfilled());
        } catch {
          yield put(actions.getSubscription.fulfilled());
          yield put(setters.setSubscription(undefined));
          sessionService.setSession({
            subscription: undefined,
          });
        }
      }
    ),
    takeLeading(
      actions.getWallet.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getWallet.pending>) {
        try {
          const wallet: IResponse<paymentService.IGetWalletData> = yield call(
            paymentService.getWallet
          );
          yield put(setters.setWallet(wallet.data));

          yield put(actions.getWallet.fulfilled());
        } catch (error) {
          yield put(actions.getWallet.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getWalletPayment.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getWalletPayment.pending>
      ) {
        try {
          yield delay(5000);

          const wallet: IResponse<paymentService.IGetWalletData> = yield call(
            paymentService.getWallet
          );
          yield put(setters.setWallet(wallet.data));

          yield put(actions.getWalletPayment.fulfilled());
        } catch (error) {
          yield put(actions.getWalletPayment.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.changeWalletBalance.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.changeWalletBalance.pending>
      ) {
        try {
          yield put(setters.setWalletBalance(action.payload.balance));

          yield put(actions.changeWalletBalance.fulfilled());
        } catch (error) {
          yield put(actions.changeWalletBalance.rejected(error as Error));
        }
      }
    ),
    takeLatest(actions.analyticUserProperties.pending.toString(), function* () {
      try {
        const user: userService.IUser | undefined = yield select(
          selectors.user
        );
        const isSubscribled: boolean = yield select(selectors.isSubscribled);
        const { email, confirmAdult, analyticsUTM } =
          sessionService.getSession();
        const provider = firebaseService.getUserProvider() as string;
        const user_id = user?.uuid;
        const userProperies = {
          provider,
          email: email || '',
          cookies_consent_status: Boolean(confirmAdult),
          subscription_state: isSubscribled,
          ...analyticsUTM,
          pwa: isPWAInstalled(),
          platform_os: platform.os?.family,
          platform_browser: platform.name,
          platform_description: platform.description,
        };

        posthog.identify(user_id, {
          provider,
          ...analyticsUTM,
          pwa: isPWAInstalled(),
        });
        gtag('set', {
          user_properties: {
            user_id,
            ...userProperies,
          },
        });
        yield put(setters.setAnalyticsUTM(analyticsUTM));
        yield put(actions.analyticUserProperties.fulfilled());
      } catch (error) {
        yield put(actions.analyticUserProperties.rejected(error as Error));
      }
    }),
    takeLeading(
      actions.analyticsUserAuth.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.analyticsUserAuth.pending>
      ) {
        try {
          const isFirstSession = sessionService.isFirstSession();
          const { firebaseIdToken, authClientToken, clientToken } =
            sessionService.getSession();
          const isNewUser =
            authClientToken && clientToken && authClientToken === clientToken;
          const isRegistration = firebaseIdToken && isNewUser;
          const isLogin = firebaseIdToken && !isNewUser;

          if (isRegistration) {
            const provider = firebaseService.getUserProvider() as string;
            facebookPixel.track('CompleteRegistration');

            analyticsEvents.userRegistered({
              registration_type: provider,
            });
            try {
              yield call(userService.postUserRegistrationComplite);
            } catch {}
          }
          if (isLogin) {
            analyticsEvents.userLoggedIn();
          }

          if (isFirstSession) {
            analyticsEvents.cohortEvent();
          }
        } catch (error) {
          yield put(actions.analyticsUserAuth.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.superOffer.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.superOffer.pending>) {
        try {
          yield put(setters.setSuperOffer(action.payload));

          const superOffer: ISuperOffer = yield select(selectors.superOffer);
          sessionService.setSession({
            superOffer,
          });
          yield put(actions.superOffer.fulfilled());
        } catch (error) {
          yield put(actions.superOffer.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.customBotCountUpdate.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.customBotCountUpdate.pending>
      ) {
        try {
          yield put(setters.setCustomBotCountUpdate(action.payload));

          yield put(actions.customBotCountUpdate.fulfilled());
        } catch (error) {
          yield put(actions.customBotCountUpdate.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.appInstall.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.appInstall.pending>) {
        try {
          yield put(setters.setAppInstall(action.payload));
          if (action.payload) {
            analyticsEvents.pwaInstalled();
          }
          yield put(actions.appInstall.fulfilled());
        } catch (error) {
          yield put(actions.appInstall.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.appNotifyAccept.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.appNotifyAccept.pending>
      ) {
        try {
          yield put(setters.setAppNotifyAccept(action.payload));
          if (action.payload) {
            analyticsEvents.pwaPushActivated();
          }
          yield put(actions.appNotifyAccept.fulfilled());
        } catch (error) {
          yield put(actions.appNotifyAccept.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getAppDailyReward.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getAppDailyReward.pending>
      ) {
        try {
          const has_subscribe: boolean = yield select(selectors.isSubscribled);

          const appDailyReward: IResponse<userService.IGetAppDailyRewardData> =
            yield call(userService.getAppDailyReward, { has_subscribe });

          yield put(setters.setAppDailyReward(appDailyReward.data));
          yield put(actions.getAppDailyReward.fulfilled());
        } catch (error) {
          yield put(actions.getAppDailyReward.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.postAppDailyReward.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postAppDailyReward.pending>
      ) {
        try {
          const has_subscribe: boolean = yield select(selectors.isSubscribled);
          yield put(setters.setAppDailyRewardStatus({ status: 'pending' }));

          const appDailyReward: IResponse<userService.IPostAppDailyRewardData> =
            yield call(userService.postAppDailyReward, { has_subscribe });

          const { balance } = appDailyReward.data;

          analyticsEvents.rewardUsed({});

          yield put(setters.setAppDailyRewardStatus({ status: 'earned' }));

          yield delay(1000);

          yield put(actions.postAppDailyReward.fulfilled());

          yield put(actions.changeWalletBalance.pending({ balance: balance }));
        } catch (error) {
          yield put(actions.postAppDailyReward.rejected(error as Error));
        }
      }
    ),
    takeEvery(
      actions.addImpression.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.addImpression.pending>) {
        try {
          const impressionParams: botService.IPostBotImpressionParams =
            yield select(selectors.impressionParams);

          yield put(
            setters.setImpressionParams([
              ...impressionParams,
              ...action.payload,
            ])
          );

          yield put(actions.postImpression.pending());
          yield put(actions.addImpression.fulfilled());
        } catch (error) {
          yield put(actions.addImpression.rejected(error as Error));
        }
      }
    ),
    takeLatest(
      actions.postImpression.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postImpression.pending>) {
        try {
          yield delay(3000);
          const impressionParams: botService.IPostBotImpressionParams =
            yield select(selectors.impressionParams);

          yield call(botService.postImpression, impressionParams);

          yield put(setters.setImpressionParams([]));

          yield put(actions.postImpression.fulfilled());
        } catch (error) {
          yield put(actions.postImpression.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.postHelloBonus.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postHelloBonus.pending>) {
        try {
          yield call(userService.postHeloBonusPopupShow, action.payload);

          yield put(setters.setHolloBonus(0));

          yield put(actions.postHelloBonus.fulfilled());
        } catch (error) {
          yield put(actions.postHelloBonus.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.postReferralApply.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postReferralApply.pending>
      ) {
        try {
          const referralApply: IResponse<userService.IPostReferralApplyData> =
            yield call(userService.postReferralApply, action.payload);

          yield put(setters.setWalletBalance(referralApply.data.balance));

          yield call(analyticsEvents.referralRedeemSuccess)

          yield put(actions.postReferralApply.fulfilled());
        } catch (error) {
          //@ts-ignore
          yield put(setters.setReferralApplyError(error?.response?.data));
          yield call(analyticsEvents.referralRedeemFailed)
          yield put(actions.postReferralApply.rejected(error as Error));
        }
      }
    ),
    takeEvery(actions.postReferralApply.clear.toString(), function* () {
      try {
        yield put(setters.setReferralApplyError(undefined));
      } catch (error) {}
    }),
    takeEvery(
      actions.referralApplyUpdatesComplited.pending.toString(),
      function* () {
        try {
          yield put(setters.setReferralRewardComplited());
          yield put(actions.postReferralApply.fulfilled());
        } catch (error) {
          yield put(actions.postReferralApply.rejected(error as Error));
        }
      }
    ),
    takeEvery(
      actions.postAppReport.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postAppReport.pending>) {
        try {
          yield call(userService.postAppReport, action.payload);
          yield put(actions.postAppReport.fulfilled());
        } catch (error) {
          yield put(actions.postAppReport.rejected(error as Error));
        }
      }
    ),
    takeEvery(
      actions.postAppReportContent.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.postAppReportContent.pending>) {
        try {
          yield call(userService.postAppReportContent, action.payload);
          yield put(actions.postAppReportContent.fulfilled());
        } catch (error) {
          yield put(actions.postAppReportContent.rejected(error as Error));
        }
      }
    ),
  ]);
}
