import { subDays, isAfter } from 'date-fns';
import { createSelector } from 'reselect';
import { createCommunicationSelectors } from 'src/helpers/redux';
import { IState, PREFIX, TGlobalStateWithService } from './state';
import { actions } from './actions';

// import { isOldSubscriptionPrice } from 'src/services/RomanticAI/products';
// import { isStaging } from 'src/utils/environment';

import { hasAnalyticUTMRetargeting } from 'src/services/analytics';

export const selectData = (state: TGlobalStateWithService): IState['data'] =>
  state[PREFIX].data;

export const selectCommunication = (
  state: TGlobalStateWithService
): IState['communication'] => state[PREFIX].communication;

export const communications = createCommunicationSelectors<
  TGlobalStateWithService,
  IState['communication'],
  typeof actions
>(selectCommunication, actions);

const selectSubscription = createSelector(
  selectData,
  (state) => state.subscription
);

// const selectIsOldServiceSubscription = createSelector(
//   selectSubscription,
//   (subscription) => {
//     if (subscription) {
//       const { price_id } = subscription;

//       return isOldSubscriptionPrice(price_id);
//     }

//     return false;
//   }
// );

// const selectIsAlreadyResubscription = createSelector(
//   [selectSubscription],
//   (subscription) => {
//     if (subscription && isOldService) {
//       const { end_datetime } = subscription;
//       const currentSubscriptionEndDate = new Date(end_datetime);
//       const currentDate = new Date();
//       const alreadyResubscriptionDate = subDays(
//         currentSubscriptionEndDate,
//         isStaging ? 8 : 3
//       ); // -3 days
//       const isAlready = isAfter(currentDate, alreadyResubscriptionDate);

//       return isAlready;
//     }
//     return false;
//   }
// );

export const selectors = {
  data: createSelector(selectData, (state) => state),
  appInstall: createSelector(selectData, (state) => state.appInstall),
  appNotifyAccept: createSelector(selectData, (state) => state.appNotifyAccept),
  clientToken: createSelector(selectData, (state) => state.clientToken),
  firebaseIdToken: createSelector(selectData, (state) => state.firebaseIdToken),
  subscription: selectSubscription,
  subscriptionEmail: createSelector(selectData, (state) => state.subscriptionEmail),
  isSubscriblePending:  createSelector(selectSubscription, (subscription) => {
    return subscription?.status === 'created'
  }),
  isSubscribled: createSelector(selectSubscription, (subscription) => {
    if (subscription) {
      if (subscription.payment_provider === 'stripe'){
        if (subscription?.is_lifetime) return true;
        return isAfter(new Date(subscription.end_datetime), new Date());
      }
      if (subscription.payment_provider === 'paypal'){
        return subscription.status === 'active' || subscription.status === 'created';
      }
    }

    return false;
  }),
  // isOldServiceSubscription: selectIsOldServiceSubscription,
  // isAlreadyResubscription: selectIsAlreadyResubscription,
  profile: createSelector(selectData, (state) => state.profile),
  user: createSelector(selectData, (state) => state.user),
  userId: createSelector(selectData, (state) => state.user?.uuid? state.user?.uuid.split(':')[0]: undefined),
  confirmAdult: createSelector(selectData, (state) => state.confirmAdult),
  wallet: createSelector(selectData, (state) => state.wallet),
  isClient: createSelector(selectData, (state) => Boolean(state.clientToken)),
  isUser: createSelector(selectData, (state) => Boolean(state.jwt?.access_token)),
  emailVerification: createSelector(selectData, (state) => state.emailVerification),
  analyticsUTM: createSelector(selectData, (state) => state.analyticsUTM),
  analyticsUTMRetargeting: createSelector(selectData, (state) => hasAnalyticUTMRetargeting(state.analyticsUTM)),
  superOffer: createSelector(selectData, state=>state.superOffer),
  customBotCount:createSelector(selectData, state=>state.user?.new_custom_bot_count || 0),
  pricingRules:createSelector(selectData, state=>state.user?.pricing_rules),
  appDailyReward:createSelector(selectData, state=> state.appDailyReward),

  canUseCreateCustomBot: createSelector(selectData, state=>
    state?.user?.pricing_rules?.custom_bot?.can_use_constructor_flow ||
    state?.user?.pricing_rules?.custom_bot?.can_use_user_image_flow
  ),
  appDailyRewardAvailable:createSelector(selectData, state=>
    state.appDailyReward?.can_earn_today),

  isUpgradeToSexMode:createSelector(selectData, state=>(
    state.user?.pricing_rules?.flow === 'new_pricing' &&
    state.subscription?.groups?.includes('subscriptions') &&
    !state.subscription?.groups?.includes('sex_mode_subscriptions')
  )),
  isSexMode:createSelector(selectData, state=>(
    state.user?.pricing_rules?.flow === 'new_pricing' &&
    state.subscription?.groups?.includes('sex_mode_subscriptions')
  )),
  isSendMessageRequiredBalance: createSelector(selectData, state=>{
    const cost_text_message = state.user?.pricing_rules?.chat?.cost_text_message
    const balance = state.wallet?.balance || 0;

    const isRequiredBalance = cost_text_message?(
      balance < cost_text_message
    ): false;


    return isRequiredBalance
  }),
  impressionParams:createSelector(selectData, state=>state.impressionParams),
  jwt:createSelector(selectData, state=>state.jwt),
  referralCodeError: createSelector(selectData, state=>state.referralCodeError),
  referralRewards: createSelector(selectData, state=>{
    if (state.referralRewardLastDate){
      return state.user?.updates.filter((update)=>{
        if (update.type !== 'REFERRAL_REWARD'){
          return false;
        }

        try {
          if (state.referralRewardLastDate){
            return new Date(update.created_at).getTime() > new Date(state.referralRewardLastDate).getTime()
          }
          return true
        } catch (error) {
          return false
        }
      })
    } else {
      return state.user?.updates.filter((update)=>update.type === 'REFERRAL_REWARD')
    }
  })
};

