import React from 'react';
import classNames from 'classnames/bind';
import styles from './ReferralRewards.module.scss';
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { useAccountAction, useAccountSelect } from 'src/hooks/useAccount';
const sx = classNames.bind(styles);

export const ReferralReward = ()=> {
  const user = useAccountSelect.user()
  const referralRewards = useAccountSelect.referralRewards()
  const referralApplyUpdatesComplited = useAccountAction.referralApplyUpdatesComplited()

  const isSingle = referralRewards?.length  === 1;

  const reward = React.useMemo(()=>
    referralRewards?.
      reduce((reward,item)=>reward + item.data.reward,0),[referralRewards])

  if (referralRewards?.length === 0 || !user){
    return <></>
  }

  return (
    <Modal onClose={referralApplyUpdatesComplited}>
    <ModalContent
      className={sx('modal-content')}
      onClose={referralApplyUpdatesComplited}
    >
      <ModalTitle className={sx('modal-title')}>
        <div className={sx('modal-icon')}>🥳</div>
        Congratulations!
      </ModalTitle>

      {isSingle ? (
        <div className={sx('modal-description')}>
          <p>
            Your friend recently joined the app!
          </p>
          <p>
          <span>💛{' '}{reward}</span> were added to your balance!
          </p>
          <p>
            <br/>
            Thanks for sharing our app with your friends! 🥰
          </p>
        </div>
      ): (
        <div className={sx('modal-description')}>
          <p>
          <span>{referralRewards?.length}</span> of friends recently joined the app!
          </p>
          <p>
          <span>💛{' '}{reward}</span> were added to your balance!
          </p>
          <br/>
          Thanks for sharing our app with your friends! 🥰
      </div>
      )}


      <ModalActions>
        <Button
          variant="filled"
          color="secondary"
          size="lg"
          fuild
          onClick={()=>referralApplyUpdatesComplited()}
          className={sx('button')}
        >
          You're welcome!
        </Button>
      </ModalActions>
    </ModalContent>
  </Modal>
  )
}
