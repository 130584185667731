import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import {
  builderSagaCommunications,
  clearAllStateCommunications,
} from 'src/helpers/redux';
import { actions as moduleActions } from './actions';
import * as moduleState from './state';
import moduleSagas from './sagas';
import * as moduleSelectors from './selectors';
import { IGetAppDailyRewardData } from 'src/services/RomanticAI/user';
import * as sessionService from 'src/services/RomanticAI/session';
import './service';
export * from './state';

export const slice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    emailVerification(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['emailVerification']>
    ) {
      state.data.emailVerification = action.payload;
    },
    setSubscriptionEmail(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['subscriptionEmail']>
    ) {
      state.data.subscriptionEmail = action.payload;
    },
    setUser(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['user']>
    ) {
      state.data.user = action.payload;
      state.data.clientToken = action.payload?.uuid;

      sessionService.setSession({
        clientToken: action.payload?.uuid
      })
    },
    setFirebaseIdToken(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['firebaseIdToken']>
    ){
      state.data.firebaseIdToken = action.payload;
      sessionService.setSession({
        firebaseIdToken: action.payload
      })
    },

    setAuthClientToken(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['authClientToken']>
    ){
      state.data.authClientToken = action.payload;
      sessionService.setSession({
        authClientToken: action.payload
      })
    },
    setJWT(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['jwt']>
    ) {
      state.data.jwt = action.payload;
      sessionService.setSession({
        jwt: action.payload
      });
    },
    setClientToken(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['clientToken']>
    ) {
      state.data.clientToken = action.payload;
      sessionService.setSession({
        clientToken: action.payload
      });
    },


    setConfirmAdult(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['confirmAdult']>
    ) {
      state.data.confirmAdult = action.payload;
      sessionService.setSession({
        confirmAdult: action.payload,
        referralRewardLastDate: undefined,
      })
    },
    setProfile(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['profile']>
    ) {
      state.data.profile = {
        ...state.data.profile,
        ...action.payload,
      };

      sessionService.setSession({
        displayName: action.payload?.displayName,
        email: action.payload?.email,
        photoUrl: action.payload?.photoUrl,
      });
    },
    setSubscription(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['subscription']>
    ) {
      state.data.subscription = action.payload;
      sessionService.setSession({
        subscription:action.payload
      })
    },
    setWallet(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['wallet']>
    ) {
      state.data.wallet = action.payload;
    },
    setWalletBalance(state: moduleState.IState, action: PayloadAction<number>) {
      state.data.wallet = {
        ...state.data.wallet,
        balance: action.payload,
      };
    },
    setAnalyticsUTM(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['analyticsUTM']>
    ) {
      state.data.analyticsUTM = action.payload;
      sessionService.setSession({
        analyticsUTM:action.payload
      })
    },
    setSuperOffer(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['superOffer']>
    ) {
      state.data.superOffer = {
        ...state.data.superOffer,
        ...action.payload,
      };
    },

    setCustomBotCountUpdate(
      state: moduleState.IState,
      action: PayloadAction<number>
    ) {
      if (state.data.user) {
        state.data.user.new_custom_bot_count = action.payload
      }
    },
    setAppInstall(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['appInstall']>
    ) {
      state.data.appInstall = action.payload;
      sessionService.setSession({
        appInstall: action.payload
      })
    },
    setAppNotifyAccept(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['appNotifyAccept']>
    ) {
      state.data.appNotifyAccept = action.payload;
      sessionService.setSession({
        appNotifyAccept: action.payload
      })
    },
    setAppDailyReward(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['appDailyReward']>
    ) {
      state.data.appDailyReward = action.payload;
      sessionService.setSession({
        appDailyReward: action.payload
      })
    },
    setAppDailyRewardStatus(
      state: moduleState.IState,
      action: PayloadAction<{ status: IGetAppDailyRewardData['schedule'][string]['status'] }>
    ) {
      const reward = state.data.appDailyReward as IGetAppDailyRewardData;
      if (reward?.schedule) {
        state.data.appDailyReward = {
          ...state.data.appDailyReward,
          can_earn_today: action.payload.status === 'earned' ? false : reward.can_earn_today,
          schedule: {
            ...reward?.schedule,
            [reward.today]: {
              ...reward.schedule[reward.today],
              status: action.payload.status
            }
          }
        } as IGetAppDailyRewardData

      } else {

        if (state.data?.appDailyReward?.can_earn_today) {
          state.data.appDailyReward.can_earn_today = false;
        }

      }
    },
    setImpressionParams(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['impressionParams']>
    ) {
      state.data.impressionParams = action.payload;
    },
    setHolloBonus(
      state: moduleState.IState,
      action: PayloadAction<number>
    ) {
      if (state.data.user){
        state.data.user.bonus_popup_hello_bonus = action.payload
      }
    },
    setReferralApplyError(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['referralCodeError']>
    ){
      state.data.referralCodeError = action.payload
    },
    setReferralRewardComplited(
      state: moduleState.IState,
      action: PayloadAction<void>
    ){
      if (state.data.user?.updates){
        const lastReward = [...state.data.user.updates].reverse().find((update)=>update.type = 'REFERRAL_REWARD')

        if (lastReward){
          state.data.referralRewardLastDate = lastReward.created_at
          sessionService.setSession({
            referralRewardLastDate: lastReward.created_at
          })
          state.data.user.updates = state.data.user.updates.filter((update)=>update.type !== 'REFERRAL_REWARD')
        }
      }
    },
    setClear(state: moduleState.IState) {
      state.data = moduleState.getInitialData();
      clearAllStateCommunications(state.communication);
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions);
  },
});

export const setters = slice.actions;
export const actions = moduleActions;
export const reducers = { [moduleState.PREFIX]: slice.reducer };
export const selectors = moduleSelectors.selectors
export const sagas = moduleSagas;
export const state = moduleState;
