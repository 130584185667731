import { createPhaseAction } from 'src/helpers/redux';

import * as dialogServiceTypes from 'src/services/RomanticAI/dialog/types';
import * as botServiceTypes from 'src/services/RomanticAI/bot/types';
import { postBotDialog } from 'src/services/RomanticAI/bot';

export const PREFIX = 'dialog';
export const SAGA = `${PREFIX}/saga`;

export interface IWriteMessagePayload {
  dialogId: number;
  message: string;
}

export const actions = {

  getDialogs: createPhaseAction<void>(`${SAGA}/getDialogs`),

  getDialogHistory:
    createPhaseAction<dialogServiceTypes.IGetDialogHistoryParams>(
      `${SAGA}/getDialogHistory`
    ),
  getDialogHistoryMore:
    createPhaseAction<dialogServiceTypes.IGetDialogHistoryParams>(
      `${SAGA}/getDialogHistoryMore`
    ),
  clearGetDialogHistory:
    createPhaseAction(
      `${SAGA}/clearGetDialogHistory`
    ),
  postDialogMessage:
    createPhaseAction<dialogServiceTypes.IPostDialogMessageParams>(
      `${SAGA}/postDialogMessage`
    ),
  postDialogMessageUnblur: createPhaseAction<
      dialogServiceTypes.IPostDialogMessageUnblurParams &
      { price: number, bot_name: string, priority_category?: string }
    >(
      `${SAGA}/postDialogMessageUnblur`
    ),

  addDialogChatBotMessage: createPhaseAction<{
    dialogId: number;
    message: dialogServiceTypes.IMessage;
  }>(`${SAGA}/addDialogChatBotMessage`),

  appendChatUserMessage: createPhaseAction<{
    dialogId: number;
    messages: dialogServiceTypes.IMessage[];
  }>(`${SAGA}/appendChatUserMessage`),

  highDemandAlertOpen: createPhaseAction<boolean>(
    `${SAGA}/highDemandAlertOpen`
  ),
  addCustomBot: createPhaseAction<dialogServiceTypes.IBot>(
    `${SAGA}/addCustomBot`
  ),


  wiriteMessage: createPhaseAction<IWriteMessagePayload>(
    `${SAGA}/wiriteMessage`
  ),

  getBotSearch: createPhaseAction<botServiceTypes.IPostBotSearchParams>(
    `${SAGA}/getBotSearch`
  ),
  getBotRecent: createPhaseAction<botServiceTypes.IGetBotRecentParams>(
    `${SAGA}/getBotRecent`
  ),
  getCategories: createPhaseAction<botServiceTypes.IGetCategoriesParams>(
    `${SAGA}/getCategories`
  ),
  getBotsPriorityList: createPhaseAction<botServiceTypes.IGetBotsPriorityListParams>(
    `${SAGA}/getBotsPriorityList`
  ),

  getBotsPriorityListMore: createPhaseAction<void>(
    `${SAGA}/getBotsPriorityListMore`
  ),

  getMyBots: createPhaseAction<botServiceTypes.IGetMyBotsParams>(
    `${SAGA}/getMyBots`
  ),

  getMyBotsMore: createPhaseAction<void>(
    `${SAGA}/getMyBotsMore`
  ),

  postBotDialog: createPhaseAction<botServiceTypes.IPostBotDialogParams>(
    `${SAGA}/postBotDialog`
  ),
  postImageGalleryUnblur: createPhaseAction<dialogServiceTypes.IPostImageGalleryUnblurParams>(
    `${SAGA}/postImageGalleryUnblur`
  ),
  postDialogMessageFeedback: createPhaseAction<dialogServiceTypes.IPostDialogMessageFeedbackParams &
  { themesNames?: string[], message_type: 'text' | 'image'}
  >(
    `${SAGA}/postDialogMessageFeedback`
  ),
  postDialogClear: createPhaseAction<dialogServiceTypes.IPostDialogClearParams>(
    `${SAGA}/postDialogClear`
  ),
  postDialogRemove: createPhaseAction<dialogServiceTypes.IPostDialogDeleteParams>(
    `${SAGA}/postDialogRemove`
  ),
  deleteBot: createPhaseAction<botServiceTypes.IDeleteBotParams>(
    `${SAGA}/deleteBot`
  ),
};
