import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routerHistory } from 'src/router';

interface IUseHistoryBackProps {
  initial?: string;
}
export const useRouterHistoryBack = (props?: IUseHistoryBackProps) => {
  const navigate = useNavigate();

  return React.useCallback(() => {
    const location = routerHistory.getPrevLocation();

    if (location) {
      navigate({ pathname: location?.pathname, search: location.search });
    } else {
      navigate(props?.initial || '/');
    }
  }, []);
};
