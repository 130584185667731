import React from 'react';
import classNames from 'classnames/bind';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccountSelect, useAccountAction } from 'src/hooks/useAccount';
import { useRouterHistoryBack } from 'src/hooks/useRouterHistoryBack';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Icon } from 'src/components/Icon';

import { useSearchQuery } from 'src/hooks/useSearchQuery';
import styles from './DocsPage.module.scss';
import { Header } from 'src/components';
const sx = classNames.bind(styles);

const docsOptions = [
  { label: '18 U.S.C. 2257 Exemption', url: '18_U_S_C_2257_Exemption.pdf' },
  { label: 'Blocked Content Policy', url: 'Blocked_Content_Policy.pdf' },
  { label: 'Complaint Policy', url: 'Complaint_Policy.pdf' },
  { label: 'Content Management Policy', url: 'Content_Management_Policy.pdf' },
  { label: 'Content Removal Policy', url: 'Content_Removal_Policy.pdf' },
  { label: 'Cookies Policy', url: 'Cookies_Policy.pdf' },
  { label: 'DMCA Policy', url: 'DMCA_Policy.pdf' },
  { label: 'Terms & Conditions', url: 'Terms_&_Conditions.pdf' },
  { label: 'Underage Policy', url: 'Underage_Policy.pdf' },
];
export default function DocsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const historyBack = useRouterHistoryBack();

  return (
    <div className={sx('container')}>
      <div className={sx('main')}>
        <div className={sx('title')}>
          <div className={sx('back-button')}>
            <Button
              variant="texted"
              size="sm"
              color="secondary"
              onClick={() => historyBack()}
            >
              <Icon name="Back" />
            </Button>
          </div>
          <h3>Legal information</h3>
        </div>
        <div className={sx('list')}>
          {docsOptions.map((item) => (
            <a href={`/assets/documents/${item.url}`} target="__blank" className={sx('item')}>
              <Button variant='outlined' fuild className={sx('item-button')}>{item.label}</Button>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
