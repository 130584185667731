import * as types from './types';
import { EVENTS, PurchaseEvent } from './types';
import { gtagAsync } from '../providers/GoogleTagManager';
import { posthogCapture } from '../providers/Posthog';
import { getSession } from 'src/services/RomanticAI/session';
import { facebookPixel } from '../providers/FacebookPixel';

export * from './types';

/**
 * Docs:
 * @link https://www.notion.so/3157f68bc7224227ba97a42152b68edc?v=ebffbf3433a7407799cf42aba39bc752
 */
export const analyticsEvents = {
  /** При первом входе на сервис, если нет наших cookies */
  cohortEvent: async (payload?: types.ICohortEventPayload) => {
    // posthogCapture(EVENTS.cohort_event, payload)
    // await gtagAsync(EVENTS.cohort_event, payload)
  },

  /** При отказе/принятии условий сервиса */
  consentGranted: async (payload: types.IConsentGrantedPayload) => {
    await gtagAsync(EVENTS.consent_granted, payload);
  },

  /** Отправляется каждый раз когда пользователь открывает MyChats или Explore */
  screenShown: async (payload: types.IScreenShownPayload) => {
    await gtagAsync(EVENTS.screen_shown, payload);
  },

  /** Выход с экрана чата с ботом по клику на “Back” */
  leaveChat: async (payload: types.ILeaveChatPayload) => {
    await gtagAsync(EVENTS.leave_chat, payload);
  },

  /** Отправляется каждый раз когда пользователь входит в чат с ботом */
  startedChat: async (payload: types.IStartedChatPayload) => {
    posthogCapture(EVENTS.started_chat, payload);
    await gtagAsync(EVENTS.started_chat, payload);
  },

  /** Отправка пользователем сообщения */
  userSentMessage: async (payload: types.IUserSentMessagePayload) => {
    posthogCapture(EVENTS.user_sent_message, payload);
    await gtagAsync(EVENTS.user_sent_message, payload);
  },

  /** Отправка ботом изображения */
  botSentImage: async (payload: types.IBotSentImagePayload) => {
    // posthogCapture(EVENTS.bot_sent_image, payload)
    // await gtagAsync(EVENTS.bot_sent_image, payload)
  },

  /** Бот отправил сообщений */
  botSentMessage: async (payload: types.IBotSentMessagePayload) => {
    await gtagAsync(EVENTS.bot_sent_message, payload);
  },

  /** В момент показа pop-up’ов */
  popUpShown: async (payload: types.IPopUpShownPayload) => {
    // await gtagAsync(EVENTS.pop_up_shown, payload)
    // posthogCapture(EVENTS.pop_up_shown, payload)
  },

  /** В момент показа paywall подписки */
  subscriptionPaywallShown: async (
    payload: types.ISubscriptionPaywallShownPayload
  ) => {
    const session = getSession();

    posthogCapture(EVENTS.subscription_paywall_shown, payload);
    await gtagAsync(EVENTS.subscription_paywall_shown, {
      ...payload,
      ...session.analyticsUTM,
    });
  },

  /** В момент перехода в платёжную систему */
  subscriptionCheckout: async (payload: types.ISubscriptionCheckoutPayload) => {
    posthogCapture(EVENTS.subscription_checkout, payload);
    await gtagAsync(EVENTS.subscription_checkout, payload);
  },

  /** В момент перехода в платёжную систему */
  coinsCheckout: async (payload: types.ICoinsCheckoutPayload) => {
    posthogCapture(EVENTS.coins_checkout, payload);
    await gtagAsync(EVENTS.coins_checkout, payload);
  },

  /** В момент успешной регистрации нового пользователя */
  userRegistered: async (payload?: types.IUserRegisteredPayload) => {
    posthogCapture(EVENTS.user_registered, payload);
    await gtagAsync(EVENTS.user_registered, payload);
  },

  /** В момент авторизации ранее зарегистрированного пользователя */
  userLoggedIn: async (payload?: types.IUserLoggedInPayload) => {
    await gtagAsync(EVENTS.user_logged_in, payload);
    posthogCapture(EVENTS.user_logged_in, payload);
  },

  /** Если показ PW показан и произошла оплата в чате с ботом */
  subscriptionPaid: async (payload: types.ISubscriptionPaidPayload) => {
    const { item, ...data } = payload;
    if (data.bot_id) {
      posthogCapture(EVENTS.dialog_converted_subscription, {
        ...item,
        ...data,
      });
    }
    facebookPixel.trackCustom(EVENTS.subscription_paid, { ...item, ...data });
    posthogCapture(EVENTS.subscription_paid, { ...item, ...data });
    facebookPixel.track('Purchase', {
      value: item.price,
      currency: 'USD',
      content_category: item.item_category,
      content_name: item.item_name,
      content_type: 'product',
      contents: [{ id: item.quantity, quantity: item.quantity }],
    });
    await gtagAsync(EVENTS.subscription_paid, { ...item, ...data });
  },

  resubscription: async (payload: types.ISubscriptionPaidPayload) => {
    const { item, ...data } = payload;

    facebookPixel.trackCustom(EVENTS.resubscription, { ...item, ...data });

    facebookPixel.track('Purchase', {
      value: item.price,
      currency: 'USD',
      content_category: 'Resubscription',
      content_name: item.item_name,
      content_type: 'product',
      contents: [{ id: item.quantity, quantity: item.quantity }],
    });
  },

  /** Пользователь успешно купил подписку. */
  subscriptionStarted: async (payload: types.IEcommercePayload) => {
    // {
    //   const { items, ...data } = payload
    //   posthogCapture(PurchaseEvent, {
    //     ...data,
    //     ...items?.[0]
    //   })
    // }
    await gtagAsync(PurchaseEvent, payload);
  },

  subscriptionStartedTest: async (payload: types.IEcommercePayload) => {
    // const { items, ...data } = payload
    // await gtagAsync('purchase_first_a', payload)
    // await gtagAsync('purchase_first_b', {
    //   ...data,
    //   ...items?.[0]
    // })
  },
  subscriptionStartedTestHighvalue: async (
    payload: types.IEcommercePayload
  ) => {
    // const { items, ...data } = payload
    // await gtagAsync('purchase_highvalue_a', payload)
    // await gtagAsync('purchase_highvalue_b', {
    //   ...data,
    //   ...items?.[0]
    // })
  },
  /** Пользователь успешно купил сердца. */
  walletHeartPaidSuccess: async (payload: types.IEcommercePayload) => {
    // posthogCapture(PurchaseEvent, payload)
    // await gtagAsync(PurchaseEvent, payload)
  },

  /** Пользователь нажал на кнопку в чате send me picture */
  sendMeAPictureButtonClick: async (
    payload?: types.ISendMeAPictureButtonClick
  ) => {
    posthogCapture(EVENTS.send_me_a_picture_button_click, payload)
    await gtagAsync(EVENTS.send_me_a_picture_button_click, payload)
  },

  createChatbot: async (payload?: types.ICreateChatbotPayload) => {
    const { source, ...data } = payload || {};

    posthogCapture(EVENTS.create_chatbot, data);
    await gtagAsync(EVENTS.create_chatbot, data);
  },

  coinsPaid: async (payload: types.ICoinsPaidPayload) => {
    const { item, ...data } = payload;
    if (data.bot_id) {
      posthogCapture(EVENTS.dialog_converted_hearts, { ...data, ...item });
    }

    posthogCapture(EVENTS.coins_paid, { ...data, ...item });

    facebookPixel.trackCustom(EVENTS.coins_paid, { ...data, ...item });
    facebookPixel.track('Purchase', {
      value: item.price,
      currency: 'USD',
      content_category: item.item_category,
      content_name: item.item_name,
      content_type: 'product',
      contents: [{ id: item.quantity, quantity: item.quantity }],
    });
    await gtagAsync(EVENTS.coins_paid, { ...data, ...item });
  },

  coinsPaywallShown: async (payload?: types.ICoinsPaywallShownPayload) => {
    posthogCapture(EVENTS.coins_paywall_shown, payload);
    await gtagAsync(EVENTS.coins_paywall_shown, payload);
  },

  purchasedUnblurPhoto: async (
    payload?: types.IPurchasedUnblurPhotoPayload
  ) => {
    // posthogCapture(EVENTS.purchased_unblur_photo, payload)
    // await gtagAsync(EVENTS.purchased_unblur_photo, payload)
  },

  userOpenedStore: async (payload: types.IUserOpenedStore) => {
    posthogCapture(EVENTS.user_opened_store, payload);
    await gtagAsync(EVENTS.user_opened_store, payload);
  },

  userPurchasedStore: async (payload: types.IUserPurchasedStore) => {
    posthogCapture(EVENTS.user_purchased_store, payload);
    await gtagAsync(EVENTS.user_purchased_store, payload);
  },

  shareBotButtonClick: async (payload: types.IShareBotButtonClick) => {
    posthogCapture(EVENTS.share_bot_button_click, payload);
    await gtagAsync(EVENTS.share_bot_button_click, payload);
  },

  pwaPopupShow: async () => {
    posthogCapture(EVENTS.pwa_popup_show);
  },

  pwaInstalled: async () => {
    posthogCapture(EVENTS.pwa_installed);
  },

  pwaPushActivated: async () => {
    posthogCapture(EVENTS.pwa_push_activated);
  },

  searchInitiated: async (payload: types.ISearchInitiated) => {
    posthogCapture(EVENTS.search_initiated, payload);
    await gtagAsync(EVENTS.search_initiated, payload);
  },

  reactionSent: async (payload: types.IReactionSent) => {
    posthogCapture(EVENTS.reaction_sent, payload);
  },

  generate: async (payload: types.IGenerate) => {
    posthogCapture(EVENTS.generate, payload);
    await gtagAsync(EVENTS.generate, payload);
  },

  rewardShown: async (payload: types.IRewardShown) => {
    posthogCapture(EVENTS.reward_shown, payload);
    await gtagAsync(EVENTS.reward_shown, payload);
  },

  rewardUsed: async (payload: types.IRewardUsed) => {
    posthogCapture(EVENTS.reward_used, payload);
    await gtagAsync(EVENTS.reward_used, payload);
  },

  requestError: async (payload: types.IRequestError) => {
    posthogCapture(EVENTS.request_error, payload);
    await gtagAsync(EVENTS.request_error, payload);
  },

  deleteProfile: async (payload: types.IDeleteProfile) => {
    posthogCapture(EVENTS.delete_profile, payload);
    await gtagAsync(EVENTS.delete_profile, payload);
  },

  resetedChat: async (payload: types.IResetedChat) => {
    posthogCapture(EVENTS.reseted_chat, payload);
    await gtagAsync(EVENTS.reseted_chat, payload);
  },

  deleteChat: async (payload: types.IDeleteChat) => {
    posthogCapture(EVENTS.delete_chat, payload);
    await gtagAsync(EVENTS.delete_chat, payload);
  },

  /**  отправляется когда юзер тыкает на кнопку реферальной программы */
  referralWidgetOpened: async (payload?: types.IReferralWidgetOpened) => {
    posthogCapture(EVENTS.referral_widget_opened, payload);
    await gtagAsync(EVENTS.referral_widget_opened, payload);
  },
  /** отправляется при успешной активации реф кода */
  referralRedeemSuccess: async (payload?: types.IReferralRedeemSuccess) => {
    posthogCapture(EVENTS.referral_redeem_success, payload);
    await gtagAsync(EVENTS.referral_redeem_success, payload);
  },
  /**  отправляется при любой неуспешной активации реф кода */
  referralRedeemFailed: async (payload?: types.IReferralRedeemFailed) => {
    posthogCapture(EVENTS.referral_redeem_failed, payload);
    await gtagAsync(EVENTS.referral_redeem_failed, payload);
  },
  /**  отправляется при нажатии на кнопку share своего реф кода */
  referralCodeShared: async (payload?: types.IReferralCodeShared) => {
    posthogCapture(EVENTS.referral_code_shared, payload);
    await gtagAsync(EVENTS.referral_code_shared, payload);
  },

  /**  отправляется при нажатии на кнопку contiune */
  userSendContinue: async (payload?: types.UserSendContinue) => {
    posthogCapture(EVENTS.user_send_continue, payload);
    await gtagAsync(EVENTS.user_send_continue, payload);
  },

  /**  отправляется при нажатии на кнопку download photo */
  userDownloadPhoto: async (payload?: types.UserDownloadPhoto) => {
    posthogCapture(EVENTS.user_download_photo, payload);
    await gtagAsync(EVENTS.user_download_photo, payload);
  },

};
