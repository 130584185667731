import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import store from 'src/redux';
import router from 'src/router';
import { useAccountAction } from 'src/hooks/useAccount';
import { initializeAnalyticsUTM } from './services/analytics';
import { googleTagManagerInstall } from './services/analytics/providers/GoogleTagManager';
import { WebsiteDoesNotWork } from './components/WebsiteDoesNotWork';
import 'src/services/sentry';
import 'src/services/analytics/providers/Posthog';

import './App.css';
import './styles/theme.scss';

initializeAnalyticsUTM()
googleTagManagerInstall()

const ReduxWrapper = () => {
  if (window.settings.WEBSITE_DOES_NOT_WORK) {
    return <WebsiteDoesNotWork />
  }
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

function App() {


  return (
    <div className="App">
      <SnackbarProvider maxSnack={3} autoHideDuration={1500}>
        <RouterProvider router={router} />
      </SnackbarProvider>
    </div>
  );
}

export default ReduxWrapper;
