import { http } from '../http';
import { ClientEvents } from './types';
import * as types from './types';
import { getSession, setSession } from '../session';

export * from './types';

export const postClientEvent = ({ event, data }: types.IPostClientEventParams) =>
  http.post(`/client-event`, { event, ...data });



/* после прохождения онбординга */
export const onboardingCompleted = (data: types.IOnboardingCompletedData) =>
  postClientEvent({ event: ClientEvents.onboarding_completed, data });

/*при открытии любого пейвола. Только 1 раз надо отправить при 1-м открытии пейвола.*/
export const paywallShown = (data: types.IPaywallShownData) =>{
  const session = getSession()
  if (!session?.rai_no_regular_paywall_shown){
    postClientEvent({ event: ClientEvents.paywall_shown, data })
    .finally(()=>setSession({ rai_no_regular_paywall_shown: true }));
  }
}
