import React from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';

import { ECoinsPaywallReason } from 'src/services/analytics/events';
import { WalletButton } from 'src/components/WalletButton';
import { useRouterSearch } from 'src/hooks/useRouterSearch';
import { useAccountSelect } from 'src/hooks/useAccount';
import { Button } from 'src/components/Button';
import { Icon } from 'src/components/Icon';

import styles from './Account.module.scss';
import { useMediaPhone } from 'src/hooks/useMediaQuery';

const sx = classNames.bind(styles);

export const Account = () => {
  const params = useParams()
  const navigate = useNavigate();
  const routerSearch = useRouterSearch();
  const isUser = useAccountSelect.isUser();
  const isSubscribled = useAccountSelect.isSubscribled();
  const profile = useAccountSelect.profile();
  const isPhone = useMediaPhone()

  const handleLogin = () => {
    if (isUser) return;
    routerSearch('modal', 'login');

  };
  const handleSignUp = () => {
    if (isUser) return;
    routerSearch('modal', 'sign-up');
  };
  return (
    <>
      {!isUser && (
        <>
          <Button
            className={sx('button')}
            color="secondary"
            variant="filled"
            size="md"
            onClick={handleLogin}
          >
            Log In
          </Button>
          <Button
            className={sx('button')}
            color="secondary"
            variant="filled"
            size="md"
            onClick={handleSignUp}
          >
            Sign Up
          </Button>
        </>

      )}
      <div className={sx('card')}>


      {isUser && (
        <WalletButton
          reason={ECoinsPaywallReason.self_heart_ballance}
        />
      )}
      {isUser && (
        <div className={sx('container')} onClick={() => navigate('/profile')}>
        <div className={sx('avatar-container')}>
          <div className={sx('avatar')}>
            {profile?.photoUrl  ? (
              <img
                className={sx('avatarImage')}
                src={profile?.photoUrl}
                alt={''}
                onError={(event)=>event.currentTarget.src = `/avatar.png`}
              />
            ) : (
              <div className={sx('avatarIcon')}>
                <Icon name="ChatProfile" />
              </div>
            )}
          </div>
            {isSubscribled && (
              <div className={sx('premium')}>premium</div>
            )}
          </div>
          {!isPhone && (
            <div className={sx('displayName')}>
              {profile?.displayName || profile?.email}
            </div>
          )}
        </div>

      )}
            </div>
    </>
  );
};
