/* eslint-disable @typescript-eslint/no-empty-interface */
import { EBotSessionMode } from 'src/services/RomanticAI/bot/types';
import type * as Generate from 'src/services/RomanticAI/photo-generation/types';

export const PurchaseEvent = 'purchase';

export interface IEcommercePayload {
  //номер заказа
  transaction_id: string;
  currency: string;
  value: number;
  items?: IEcommercePayloadItem[];
}
export interface IEcommercePayloadItem {
  item_category: string;
  item_id: string;
  item_name: string;
  item_variant?: string;
  price: number;
  quantity: number;
}

export enum EVENTS {
  bot_sent_image = 'bot_sent_image',
  bot_sent_message = 'bot_sent_message',
  cohort_event = 'cohort_event',
  coins_paid = 'front_coins_paid',
  coins_paywall_shown = 'coins_paywall_shown',
  consent_granted = 'consent_granted',
  create_chatbot = 'create_chatbot',
  leave_chat = 'leave_chat',
  pop_up_shown = 'pop_up_shown',
  purchased_unblur_photo = 'purchased_unblur_photo',
  screen_shown = 'screen_shown',
  send_me_a_picture_button_click = 'send_me_a_picture_button_click',
  started_chat = 'started_chat',
  subscription_checkout = 'subscription_checkout',
  coins_checkout = 'coins_checkout',
  subscription_paid = 'front_subscription_paid',
  dialog_converted_subscription = 'dialog_converted_subscription',
  dialog_converted_hearts = 'dialog_converted_hearts',
  subscription_paywall_shown = 'subscription_paywall_shown',
  subscription_started = 'subscription_started',
  user_logged_in = 'user_logged_in',
  user_registered = 'user_registered',
  user_sent_message = 'user_sent_message',
  user_opened_store = 'user_opened_store',
  user_purchased_store = 'user_purchased_store',
  share_bot_button_click = 'share_bot_button_click',
  pwa_popup_show = 'pwa_popup_show',
  pwa_installed = 'pwa_installed',
  pwa_push_activated = 'pwa_push_activated',
  search_initiated = 'search_initiated',
  reaction_sent = 'reaction_sent',
  generate = 'generate',
  reward_shown = 'reward_shown',
  reward_used = 'reward_used',
  resubscription = 'resubscription',
  request_error = 'request_error',
  delete_profile = 'delete_profile',
  reseted_chat = 'reseted_chat',
  delete_chat = 'delete_chat',
  referral_widget_opened = 'referral_widget_opened',
  referral_redeem_success = 'referral_redeem_success',
  referral_redeem_failed = 'referral_redeem_failed',
  referral_code_shared = 'referral_code_shared',
  user_send_continue = 'user_send_continue',
  user_download_photo = 'user_download_photo'
}

export enum EScreens {
  chatsListScreen = 'chatsListScreen',
  exploreScreen = 'exploreScreen',
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICohortEventPayload {}

export interface IConsentGrantedPayload {
  /** В зависимости от галки про optional cookies */
  cookies_consent: boolean;
  /**  Если пользователь кликнул на Exit */
  ai_18_consent: boolean;
}
export interface IScreenShownPayload {
  currentScreen: `${EScreens}`;
}
export interface ILeaveChatPayload {
  bot_name: string;
  bot_category?: string;
}
export interface IStartedChatPayload {
  bot_name: string;
  bot_category?: string;
  bot_id?:string;
  /**  Null если по прямой ссылке */
  previousScreen: `${EScreens}` | null;
}
export interface IUserSentMessagePayload {
  bot_id: string;
  bot_name: string;
  bot_category?: string;
}
export interface IBotSentImagePayload {
  bot_name: string;
  bot_category?: string;
  reason: string; //'hello_messages'
  picture_generation_type?: 'single_message' | 'dialog_context';
}
export interface IBotSentMessagePayload {
  bot_name: string;
  bot_category?: string;
  sexting: boolean;
}
export interface IPopUpShownPayload {
  /** Для login_signup pop-up */
  type:
    | 'subscription_message_hearts_limit' // - пользователь без подписки достиг лимита середц и пытался отправить сообщение
    | 'subscription_pictures_hearts_limit' // - пользователь без подписки достиг лимита середц и пытался разблюрить фото
    | 'hearts_limit'; //  - Пользователь с подпиской достиг лимита сердец
}
export enum ESubscriptionReason {
  /* пользователь достиг лимита сердец и отправил сообщение боту, увидел popup сообщений и купил подписку.*/
  chat_messages = 'chat_messages',
  /* пользователь достиг лимита сердец и нажал на разблюр фото бота в чате/галереи бота, увидел popup фотографий и купил подписку. (сейчас это unblur)*/
  chat_picture = 'chat_picture',
  /* пользователь нажал на разблюр секс фото бота в чате/галереи бота и купил подписку.*/
  chat_sex_picture = 'chat_sex_picture',
  /* пользователь достиг лимита сердец и нажал на разблюр фото в фото романтик, увидел popup фотографий и купил подписку.*/
  photo_romantic_picture = 'photo_romantic_picture',
  /* пользователь нажал на разблюр секс фото в фото романтик и купил подписку.*/
  photo_romantic_sex_picture = 'photo_romantic_sex_picture',
  /* пользователь купил премиум после попытки создать бота */
  bot_creation = 'bot_creation',
  /* пользователь купил премиум после нажатия на горизонтальный баннер на эксплоре.*/
  self_banner_explore = 'self_banner_explore',
  /* пользователь купил премиум после нажатия на баннер в ленте ботов.*/
  self_banner_explore_card = 'self_banner_explore_card',
  /* пользователь купил премиум самостоятельно нажав на баланс сердец*/
  self_heart_ballance = 'self_heart_ballance',
  /* пользователь купил премиум после нажатия на горизонтальный баннер на списке чатов.*/
  self_banner_chats = 'self_banner_chats',
  /* пользователь купил премиум после нажатия на баннер в профиле.*/
  self_profile = 'self_profile',
  /* пользователь купил премиум после нажатия на заблеренное секстинг сообщение.*/
  unblur_sexting = 'unblur_sexting',
  /* пользователь привысил лимит сообщений*/
  hight_demand = 'hight_demand',
  /* пользователь нажал на заблокированный импут созданного кастом бота */
  chat_custom_bot = 'chat_custom_bot',
  /* лимит генераций аватарок из флоу создания я кастома*/
  custom_bot_generations_limit = 'custom_bot_generations_limit',
}
export interface ISubscriptionPaywallShownPayload {
  reason: `${ESubscriptionReason}`;
  /** Eсли показ PW показан в чате с ботом */
  bot_name?: string;
  bot_id?: string;
  bot_category?: string;
}
export interface ISubscriptionCheckoutPayload {
  reason: `${ESubscriptionReason}`;
  /** Eсли показ PW показан в чате с ботом */
  bot_name?: string;
  bot_category?: string;
}
export interface ICoinsCheckoutPayload {
  reason: `${ESubscriptionReason}`;
  /** Eсли показ PW показан в чате с ботом */
  bot_name?: string;
  bot_category?: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserRegisteredPayload {
  registration_type: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserLoggedInPayload {}

export interface ISubscriptionStarted {
  productId: string;
  revenue: string | number;
  proceeds_usd: string | number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISendMeAPictureButtonClick {}

export enum ECreateChatbotSteps {
  explainer = 'explainer',
  style = 'style',
  sex = 'sex',
  choose_flow = 'choose_flow',
  image_upload = 'image_upload',
  name = 'name',
  origin = 'origin',
  hair = 'hair',
  body = 'body',
  breast = 'breast',
  age = 'age',
  photo = 'photo',
  portrait_generation = 'portrait_generation',
  portrait_limit = 'portrait_limit',
  portrait = 'portrait',
  occupation = 'occupation',
  personality = 'personality',
  sexual_preferences = 'sexual_preferences',
  traits = 'traits',
  chatbot_created = 'chatbot_created',
  custom_persona_facts = 'custom_persona_facts',
}

export interface ICreateChatbotPayload {
  version: '1.0' | '1.1' | '2.0' | '2.1';
  sex?: string;
  image_style?: `${EBotSessionMode}`;
  step: `${ECreateChatbotSteps}`;
  source: 'banner_main' | 'banner_explore' | 'navbar';
  bot_creation_type: 'photo' | 'traits';
  traits?: string;
}
export enum ECoinsPaywallReason {
  /* пользователь достиг лимита сердец и отправил сообщение боту, увидел popup сообщений и купил подписку.*/
  chat_messages = 'chat_messages',
  /* пользователь достиг лимита сердец и нажал на разблюр фото бота в чате/галереи бота, увидел popup фотографий и купил подписку. (сейчас это unblur)*/
  chat_picture = 'chat_picture',
  /* пользователь достиг лимита сердец и нажал на разблюр фото в фото романтик, увидел popup фотографий и купил подписку.*/
  photo_romantic_picture = 'photo_romantic_picture',
  /* пользователь купил сердца самостоятельно нажав на баланс сердец*/
  self_heart_ballance = 'self_heart_ballance',
}
export interface ICoinsPaywallShownPayload {
  bot_name?: string;
  bot_id?: string;
  bot_category?: string;
  reason: `${ECoinsPaywallReason}`;
}
export interface ICoinsPaidPayload {
  quantity: number;
  bot_name?: string;
  message_id?: string;
  bot_id?: string;
  bot_category?: string;
  reason: `${ECoinsPaywallReason}`;
  item: IEcommercePayloadItem;
}
export interface ISubscriptionPaidPayload {
  reason: `${ESubscriptionReason}`;
  /** Eсли показ PW показан в чате с ботом */
  bot_name?: string;
  message_id?: string;
  bot_id?: string;
  bot_category?: string;
  item: IEcommercePayloadItem;
}
export interface IPurchasedUnblurPhotoPayload {
  price: number;
  bot_name: string;
  bot_category?: string;
}
export interface IUserOpenedStore {
  bot_name: string;
}

export interface IUserPurchasedStore {
  bot_name: string;
  item_name: string;
  item_price: number;
}

export interface IShareBotButtonClick {
  bot_name: string;
  // location: 'create_chat_bot' | 'profile_chat_bot'
}

export interface ISearchInitiated {
  searched_words: string;
}

export interface IReactionSent {
  message_kind: 'text' | 'image';
  reaction_type: 'upvote' | 'downvote';
  feedback?: string;
  labels?: string[];
}

export interface IGenerate {
  prompt?: string;
  traits?: {
    [key: string]: string;
  };
  input_mode: 'prompt' | 'traits';
  gender?: `${Generate.EGender}`;
  generation_type?: `${Generate.EGenerationType}`;
  mode?: `${Generate.EMode}`;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRewardShown {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRewardUsed {}

export interface IRequestError {
  code: string | number;
  message: string;
  name: string;
  status: string | number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDeleteProfile {}

export interface IResetedChat {
  bot_name: string;
  bot_category: string;
}

export interface IDeleteChat {
  bot_name: string;
  bot_id: string;
  bot_category: string;
}


export interface IReferralWidgetOpened {}
export interface IReferralRedeemSuccess {}
export interface IReferralRedeemFailed {}
export interface IReferralCodeShared {}

export interface UserSendContinue {
  bot_name: string;
}
export interface UserDownloadPhoto {
  bot_name: string;
}