import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Account } from './Account';
import { Logotype } from './Logotype';
import { AffiliateButton } from '../AffiliateButton';
import styles from './Header.module.scss';
import { Button } from 'src/components/Button'

export const Header = () => {
  const navigate = useNavigate();
  const handleLogoClick = React.useCallback(() => {
    navigate('/');
  }, []);


  return (
    <div className={styles.container}>
      <div className={styles.logotype}>
        <Logotype onClick={handleLogoClick} />
      </div>
      <div className={styles.navigation}>
        {/* <AffiliateButton mobileShort /> */}
        <Account />
      </div>
    </div>
  );
};
